import './ImagePick.css';
import React, { useRef, useState } from 'react';
import { TbPhoto } from "react-icons/tb";

export default function ImagePick(props) {
    const [image, setImage] = useState(props.startImage);
    const ref = useRef();

    const readImage = (files) => {
        try {
            const picked = files.length > 0 ? files[0] : null;
            setImage(URL.createObjectURL(picked));
        } catch (error) {   // If file picker is cancelled
            setImage(image || props.startImage);
        }
    }

    return (
        <div className="image-pick-container" style={{ display: "flex", flexDirection: "column" }}>
            <div
                style={{ marginTop: props.image1 ? "35px" : null }}
                className='fileSelectButton'
                onClick={() => { ref.current.click() }}
            >
                {image ? "Upload a Different Photo" : "Upload a Photo"}
            </div>

            <div className='form-subtext' style={{ textAlign: "center", fontSize: 14 }}>
                <p>{`(${props.optional ? "Optional" : "Required"})`}</p>
                {props.suggestion ? <p>{`Suggested: ${props.suggestion} photo`}</p> : null}
            </div>

            <input
                style={{ display: "none" }}
                accept='image/*'
                type="file"
                name="imagePick"
                id={props.id}
                onChange={(e) => readImage(e.target.files)} 
                ref={ref}
            />

            <div
                className={props.error && (!image || !ref.current?.files[0])
                    ? "imagePickImage imagePickImageError" : "imagePickImage"}
                style={{
                    backgroundImage: ref.current?.files[0] || image ? `url(${image})` : "none",
                    backgroundColor: ref.current?.files[0] && image ? "transparent" : null,
                }}
            >
                {
                    ref.current?.files[0] || image ?
                        null
                        :
                        <div className='noPhotoUploaded'>
                            <TbPhoto className='iconImgPick' />
                            <div>No Photo Uploaded</div>
                        </div>
                }
            </div>
        </div>
    )
}