import React, { useState, useContext } from 'react';
import NewEmail from './NewEmail';
import NewPassword from './NewPassword';
import { updatePassword, reauthenticateWithCredential, EmailAuthProvider, updateEmail } from 'firebase/auth';
import '../signUp/signUp.css'
import { FirebaseAppContext } from '../../contexts/firebase';

export default function ChangeEmailOrPassword(props) {

    const { user } = useContext(FirebaseAppContext);

    const [newPasswordError, setNewPasswordError] = useState(null);   // error is present if !!newPasswordError === true
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [newEmail, setNewEmail] = useState("");
    const [newEmailError, setNewEmailError] = useState(null); // error is present if !!newEmailError === true

    const changeEmail = () => {
        if (newEmail === user.email) {
            setNewEmailError("This account already uses this email address");
        } else if (newEmail && newEmail.length > 0) {
            updateEmail(user, newEmail)
                .then(() => {
                    // email updated.
                    props.setShowItem(false);
                })
                .catch((error) => {
                    console.log(error.code);
                    if (error.code === "auth/email-already-in-use") {
                        setNewEmailError("This email is already in use");
                    } else if (error.code === "auth/invalid-email") {
                        setNewEmailError("Enter a valid email address");
                    }
                });
        } else {
            setNewEmailError("Enter a valid email address");
            setNewPasswordError(null);
        }
    }

    const changePassword = () => {
        if (newPassword === props.currentPassword) {
            setNewPasswordError("New password cannot be your current password");
        } else if (newPassword.length >= 6
            && (newPassword === confirmPassword)) {
            updatePassword(user, newPassword)
                .then(() => {
                    // password changed
                    props.setShowItem(false);
                })
                .catch((error) => {
                    console.log(error.code);
                    setNewPasswordError(error.code);
                });
        } else if (newPassword !== confirmPassword) {
            setNewPasswordError("Your passwords do not match");
        } else {
            setNewPasswordError("New password must be at least six characters");
        }
    }

    return (
        <div>
            {
                props.change === "Password" ?
                    <NewPassword
                        error={newPasswordError}
                        errorText={newPasswordError}    // same variable used for 2 props
                        blurPassword={setNewPassword}
                        blurConfirm={setConfirmPassword}
                    />
                    :
                    <NewEmail
                        error={!!newEmailError}
                        errorText={newEmailError}   // same variable used for 2 props
                        emailRef={newEmail}
                        blurEmail={setNewEmail}
                    />
            }
            <button
                id='confirmButton'
                onClick={(e) => {
                    e.preventDefault();
                    
                    const credential = EmailAuthProvider.credential(
                        user.email,
                        props.currentPassword
                    );

                    reauthenticateWithCredential(user, credential)
                        // User Re-authenticated
                        .then(() => {
                            props.onCurrentPasswordError(false);
                            props.change === "Password" ?
                                changePassword() : changeEmail();
                        }).catch((error) => {
                            console.log(error);
                            props.onCurrentPasswordError(true);
                        });
                }}>
                Change {props.change}
            </button>
        </div>
    )
}