import './inputFR.css'
import { useState } from "react";

const InputFRState = (props) => {
    const [focusedInput, setFocusedInput] = useState(false);

    return (
        <div className='inputContainer' style={{ margin: props.margin || null, }}>
            <input
                onWheel={(e) => e.target.blur()}
                type={props.type || "text"}
                min="0"
                inputMode={props.type === "number" ? "numeric" : null}
                pattern={props.type === "number" ? "[0-9]*" : null}
                value={props.value ? props.value : ""}
                onChange={(e) => {
                    props.onChange(e)
                }}
                className="inputForm"
                style={{ width: props.width || "100%" }}
                placeholder="Invisible"
                onFocus={() => { setFocusedInput(true) }}
                onBlur={() => {
                    props.onBlur();
                    setFocusedInput(false)
                }}
                id={props.id}
            />

            {/*controls text animation*/}
            <div className='labelAndBackgroundContainer'>

                <div className='inputLabel'>
                    {props.label}
                </div>

            </div>

            {/* controls background color of text */}
            <div className={props.value === "" && !focusedInput ? 'labelAndBackgroundContainer backgroundColorAni'
                : 'labelAndBackgroundContainer backgroundColorAni backgroundColorAniGo'}
            >
                {props.label}
            </div>

        </div>
    )
}
export default InputFRState;