import "./alertPop.css"
import Button from "../../Button/Button"

export default function AlertPop(props) {

  return <>
    <div className='successPop'>
      <div className="titleAndSubtitle">
        <div className='titleSucce'>
          {props.title}
        </div>
        {props.subtitle && <p className='subTitleSucce'>{props.subtitle}</p>}
      </div>

      <div className='buttonContainerSuccess'>
        {props.type === "navgateAwayOptions" && <>
            <div className="paddingAndBorder">

              <Button
                text={props.leftTitle}
                color="blue"
                onClick={() => props.onClickLeft()}
              />

              {
                props.rightTitle && <Button
                  text={props.rightTitle}
                  color="red"
                  onClick={() => props.onClickRight()}
                />
              }
            </div>

          </>}
      </div>
    </div>
  </>
}