import '../signUp/signUp.css'
import React, { useState, useRef, useContext } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import InputFR from '../../components/formComponents/inputText/InputFR';
import { TbAlertCircle } from "react-icons/tb";
import { FirebaseAppContext } from '../../contexts/firebase';

export default function ResetPassword() {

  const { auth } = useContext(FirebaseAppContext);

  const emailRef = useRef();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const sendEmail = () => {
    sendPasswordResetEmail(auth, emailRef.current[0].value)
      .then(() => {
        setError("");
        setSuccess(true);
      })
      .catch((error) => {
        if (error.code === "auth/missing-email" || error.code === "auth/invalid-email") {
          setError('Please enter a valid email');
        } else if (error.code === 'auth/user-not-found') {
          setError('User with this email does not exist');
        }
      })
  }

  return (
    <div id="authForm">
      {success ?
        <>
          <div className='authTitles'>Reset Password</div>
          <div className="emailSentTitle" >The email to reset your password has been sent to <b style={{ color: "#2a8094" }}>{emailRef.current[0].value}</b>!</div>
          <div>Make sure to check your junk and spam if it doesn't appear in your inbox!</div>
        </>
        :
        <form 
          id="authForm" 
          onSubmit={(e) => { e.preventDefault(); sendEmail(); }}
          ref={emailRef}
        >
          <div className='authTitles'>Reset Password</div>

          <div className={error ? 'warning emailError error' : 'hideEmailError'}>
            <TbAlertCircle className='errorIconsAuth' />
            <div>
              {error}
            </div>
          </div>

          <InputFR
            type="text"
            error={error}
            label="Email"
            id="email"
            width="100%"
          />

          <input id="confirmButton"
            type="submit"
            value="Reset Password"
          />

        </form>
      }
    </div>
  )
}