import "./header.css"
import Button from "../Button/Button"

export default function HeaderButtonNoAuth(props) {

    return (
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

            <div className="textHome signInText">
                Ready to publish your recovery house?
            </div>

            <Button
                text="Add My Recovery House"
                onClick={props.signUpClick}
                width={250}
            />

        </div>
    )
}
