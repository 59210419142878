import "./PopBackground.css";
import React, { useEffect } from "react";

export default function PopBackground(props) {

    // lock body scrolling when a pop is visible.
    // unlocks when closed
    useEffect(() => {
        if (props.visible) {
            document.body.classList.add("noscroll")
        }
        return () => document.body.classList.remove("noscroll");
    }, [props.visible]);

    return <>
        <div className={`backgroundAlert ${props.visible ? "backgroundPopFadeIn" : "backgroundPopFadeOut"}`}
            onClickCapture={(e) => {
                if (props.navOut && e.target.id === "pop-background") {
                    props.hidePop(false);
                }
            }}
            id="pop-background"
        >
            <div className="containerLoadSuccess" style={{ display: props.visible ? "block" : "none" }}>
                <div className="popContent" style={{
                    // styles are inline here based on how the component should be centered
                    top: props.centered ? "50%" : 0,
                    transform: props.centered ? "translate(-50%, -50%)" : "translateX(-50%)"
                }}>
                    {props.component}
                </div>
            </div>
        </div>
    </>
}