import '../searchComponents/locationSearchBar/LocationSearchBar.css'
import React, { useState, useRef, useEffect } from 'react';
import locationApiSearch from '../../functions/locationApiSearch';
import { TbSearch, TbMapPin } from 'react-icons/tb';
import getLocation from "../../functions/getLocation";
import Geocode from "react-geocode"


/**
 * The actual search bar that handles searching by location
 * @param {*} props React props 
 * @returns Search Bar component used for searching for a location
 */
export default function LocationSearch(props) {

    const [btnVisible, setBtnVisible] = useState(false);
    const text = useRef();

    const [currentSearch, setCurrentSearch] = useState("");

    const [currentLocation, setCurrentLocation] = useState(null);
    const [locationText, setLocationText] = useState(null);

    // Call the Google Geocode API and send the new location up to search results
    async function getNewLocation(e, value) {
        e.preventDefault();
        const newLocation = await locationApiSearch(value, true);

        /**
         * Handles invalid searches
         * @see locationApiSearch
         */
        if (!!newLocation) {
            props.onNewSearch({ location: newLocation, text: value });
            setCurrentSearch(value)
        } else {
            alert(`"${value}" could not be found. Please try a new search.`)
            setCurrentSearch("")
        }
    }


    /**
     * effect to get user's location, pulled from App.js.
     * Should remove warnings about pulling user's location from
     * non-user generated gestures
     */
    useEffect(() => {
        async function pullLocation() {
            return document.activeElement === text.current && btnVisible ? await getLocation() : null;
        }
        pullLocation()
            .then((value) => {
                //console.log("THEN loader")
                if (value.coords) {
                    setCurrentLocation({ latitude: value.coords.latitude, longitude: value.coords.longitude });

                    Geocode.setApiKey("AIzaSyB44skzvQcY8fJMz7MZc4OKR4wL9KbxOwQ");
                    Geocode.setLocationType("ROOFTOP");

                    // Geocodes user's location to address via Google since the HTML geocode doesn't.
                    return Geocode.fromLatLng(value.coords.latitude, value.coords.longitude)
                        .then((response) => {
                            const address = response.results[0].address_components;
                            const formatted = `${address[3].long_name}, ${address[6].short_name} ${address[8].long_name}`;
                            setLocationText(formatted);
                        },
                            (error) => {
                                console.log("Error in reverse geocoding user location:", error.message);
                            });
                }
            })
            .catch((error) => {
                console.log("Error in getting location:", error.message)
                setCurrentLocation(null);
                setLocationText(null);
            })

    }, [btnVisible]);

    return (
        <form className={props.homeScreen ? 'homeScreenFormSearch'/*change this className for different styles */ : 'formSearch'}>

            <input key="uniqueKey3" className='searchTextBox' id='search-text-box' placeholder="Address, City, or Zip Code"
                //autoComplete='street-address'
                defaultValue={!!props.startText ? props.startText : ""}
                ref={text}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        if (text.current.value !== currentSearch) {
                            getNewLocation(e, e.target.value);
                            window.scrollTo(0, 0);
                        }
                        setBtnVisible(false);
                        e.target.blur();
                    }
                }}
                onFocus={() => { setBtnVisible(true); }}
                onBlur={() => setBtnVisible(false)}
            />


            <div className='searchIcon' style={{ color: btnVisible ? "#2a8094" : null }}
                onClick={(e) => {
                    if (text.current.value !== currentSearch) {
                        getNewLocation(e, text.current.value);
                        window.scrollTo(0, 0);
                    }
                }}>
                <TbSearch />
            </div>

            {

                (btnVisible && currentLocation) &&
                <div className='use-current'
                    onMouseDown={() => {
                        setBtnVisible(false);
                        text.current.value = locationText
                        setCurrentSearch(props.currentLocationText);
                        if (props.currentLocationText !== currentSearch) {
                            props.onUseCurrent({
                                location: currentLocation,
                                text: locationText
                            });
                            window.scrollTo(0, 0)
                        }
                        setCurrentSearch(props.currentLocationText)
                    }}>
                    <TbMapPin className='locationIcon' />
                    <p className='useCurrText'>Use Current Location</p>
                </div>
            }

        </form>
    );
}