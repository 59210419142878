import "./title.css"
import { IoChevronBack, IoClose } from "react-icons/io5";

const Title = (props) => {

    return (
        <div className="containerTitle" onClick={() => props.setSelectedHouse(false)}>
            <IoChevronBack className="backIcon" />
            <IoClose className="closeIcon" />
            <div className="title">
                {props.title}
            </div>
        </div>
    )
}
export default Title;