import './LocationSearchBar.css';
import { IoFilter } from "react-icons/io5";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { IoChevronBack } from "react-icons/io5";

import { useNavigate } from "react-router-dom";

import LocationSearch from '../../locationSearch/LocationSearch';

/**
 * This component has the text search bar for searching a location.
 * @param {*} props React props
 * @returns The visual component of the search bar
 */

export default function LocationSearchBar(props) {
    const navigate = useNavigate();

    return (
        <div id='header-container'>
            <div className="searchAndFilterButtonContainer">

                <div className='backButtonLocationSearch'
                    onClick={() => {
                        // determine if survey should be shown
                        const surveyCompleted = localStorage.getItem("surveyCompleted") === "true";
                        const timeSinceLastDismiss = new Date(localStorage.getItem("surveyDateDismissed")).getTime();
                        const currentTime = new Date().getTime();

                        window.scrollTo(0, 0)
                        navigate("/", {
                            state: {
                                bookmarkedHouses: props.bookmarkedHouses,
                                currentLocation: props.currentLocation,
                                survey: !surveyCompleted && (isNaN(timeSinceLastDismiss) || currentTime - timeSinceLastDismiss >= 604800000)
                            }
                        });
                    }}>
                    <IoChevronBack className="backIconLocationSearch" />
                </div>


                <LocationSearch
                    homeScreen={false}
                    startText={props.startText}
                    onNewSearch={(e) => props.onNewSearch(e)}
                    onUseCurrent={props.onUseCurrent}
                    currentLocationText={props.currentLocationText}
                />

                <div className='filterButton' onClick={() => {
                    document.body.scrollBy(0, 100); props.onShowFilters(true);
                }}>

                    <AiOutlineMenuUnfold className='filterButtonIconMenu' />
                    <IoFilter className='filterButtonIconFilter' />
                    <div className='filterButtonText'>Filters</div>

                </div>

            </div>
        </div>
    )
}