import './locationScreenButton.css';
import { useState } from 'react';
import { BsBookmark, BsBookmarkCheckFill } from "react-icons/bs";
import { FiGlobe } from "react-icons/fi";

const LocationScreenButton = (props) => {
    let bookmarkedHouses = props.bookmarkedHousesValue;
    const [bookmarked, setBookmarked] = useState(props.bookmarkedHousesValue?.indexOf(props.selectedKey) === -1 ? false : true)

    const clickFunctionBook = () => {
        if (bookmarkedHouses.indexOf(props.selectedKey) === -1) {
            bookmarkedHouses.push(props.selectedKey)
        }
        else {
            let index = bookmarkedHouses.indexOf(props.selectedKey);
            bookmarkedHouses.splice(index, 1);
        }
        setBookmarked(prev => !prev)
        props.setBookmarkedHousesValue(bookmarkedHouses)
    }

    return (
        <div onClick={() => { props.label === "Bookmark" && clickFunctionBook() }} className= {props.disabled ? 'locationScreenButton locationScreenButtonDisabled' : 'locationScreenButton'}>
            <div>
                {props.label === "Website" ?
                    <FiGlobe style={{ fontSize: "24px" }} className= {props.disabled ? 'icon iconDisabled' : 'icon'} />
                    :
                    bookmarked ?
                            <BsBookmarkCheckFill className='icon' /> : <BsBookmark strokeWidth={.35} className='icon' />
                }
            </div>
            <div>
                {props.label}
            </div>
        </div>
    )
}
export default LocationScreenButton;