import React, { useContext } from "react";
import {
  RouterProvider,
  createBrowserRouter,
  redirect,
} from "react-router-dom";
import Home from "./pages/HomeNoAuth/Home";
import Search from "./pages/search/Search";
import Form from "./pages/form/Form";
import AuthHome from "./pages/authHome/AuthHome";
import { Admin } from "./pages/admin/Admin";
import { FirebaseAppContext, FirebaseAppProvider } from "./contexts/firebase";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import "./App.css";

function App() {

  // Custom theme for Chakra UI
  const theme = extendTheme({
    colors: {
      rblue: {
        50: '#f4fafc',
        100: '#e5f4f7',
        200: '#bde3ec',
        300: '#8cc9dd',
        400: '#71c3d7',
        500: '#56bdc1',
        600: '#2a8094', // is 'rsilience blue'
        700: '#216675',
        800: '#193553',
        900: '#0f1f32',
      },
    },
  });

  /**
   * Check out the contexts folder (./contexts) to see why
   * this is set up the way it is or the documentation
   * https://react.dev/reference/react/useContext
   * https://react.dev/reference/react/createContext
   */
  return (
    <FirebaseAppProvider>
      <ChakraProvider theme={theme}>
        <Router />
      </ChakraProvider>
    </FirebaseAppProvider>
  );
}

const Router = () => {
  const { user, admin } = useContext(FirebaseAppContext);

  /*
   * Creates a browser router that is compatible with loaders.
   * Loaders can send data to component prior to render.
   * Can prevent users from accessing pages that they shouldn't
   * or possibly loading Firebase data prior to component render.
   */
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      loader: () => {
        // if user is signed in, send them to dashboard
        if (user) return redirect("/home");
        return null;
      },
    },
    {
      path: "/admin",
      element: <Admin />,
      loader: () => {
        if (!user || !admin) return redirect("/home");
        return null;
      },
    },
    {
      path: "/search",
      element: <Search />,
    },
    {
      path: "/home",
      element: <AuthHome />,
      loader: () => {
        // if user is not signed in, send them to root directory
        if (!user) return redirect("/");
        return null;
      },
    },
    {
      path: "/form",
      element: <Form />,
      loader: () => {
        // if user is not signed in, send them to root directory
        if (!user) return redirect("/");
        return null;
      },
    },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
