import { useState, useContext, useLayoutEffect } from "react";
import { FirebaseAppContext } from "../../../contexts/firebase";
import { update, ref, set } from "firebase/database";
import { v4 as uuidv4 } from 'uuid';
import "./Survey.css";
import "../alertPop/alertPop.css";
import Button from "../../Button/Button";

export default function Survey(props) {

    const { user, database, setSurveyDateDismissed, setSurveyCompleted } = useContext(FirebaseAppContext);

    const [surveyAnswers, setSurveyAnswers] = useState([
        { key: "colleagueOrFriend", display: "Colleague or friend", checked: false },
        { key: "email", display: "Email", checked: false },
        { key: "flyerOrPostCard", display: "Flyer or post-card", checked: false },
        { key: "conference", display: "Conference", checked: false },
        { key: "socialMedia", display: "Social Media", checked: false },
        { key: "phone", display: "Phone Call", checked: false }
    ]);

    const [recommend, setRecommend] = useState(null);
    const [recommendError, setRecommendError] = useState(false);

    const handleCheck = (index) => {
        surveyAnswers[index].checked = !surveyAnswers[index].checked;
        setSurveyAnswers([...surveyAnswers]);
    }

    useLayoutEffect(() => {
        if (recommend !== null) {
            setRecommendError(false);
        }
    }, [recommend]);

    const surveySubmit = () => {
        if (!recommend) {
            console.log("no recommend score");
            setRecommendError(true);
        } else {
            const surveyResponse = { recommend: recommend, date: new Date().toISOString(), houseOwner: !!user };
            surveyAnswers.forEach((element) => {
                surveyResponse[element.key] = element.checked;
            });

            // console.log(surveyResponse);
            const surveyKey = uuidv4();
            set(ref(database, `surveyResponses/${surveyKey}`), surveyResponse)
                .then(() => {
                    console.log(surveyKey, "published")

                    if (user) {
                        update(ref(database, `users/${user.uid}`), {
                            surveyDateDismissed: null,
                            surveyCompleted: true
                        })
                            .then(() => {
                                // state if signed in
                                console.log("user object updated", user.uid);
                                setSurveyCompleted(true)
                                setSurveyDateDismissed(null)
                                props.navAfter();
                            });
                    } else {
                        // local storage if not signed in
                        localStorage.removeItem("surveyDateDismissed");
                        localStorage.setItem("surveyCompleted", "true");
                        props.navAfter();
                    }
                })
        }
    }

    return <>
        <div className={props.controlledBy ? 'backgroundAlert backgroundPopFadeIn' : 'backgroundAlert backgroundPopFadeOut'} />
        <div className="surveyContainer">

            <div className="surveyQ">
                <h4 className="surveyHeader">Where did you hear about R-silience?</h4>
                <p className="surveysub">(Check all that apply)</p>
                {
                    surveyAnswers.map((element, index) => {
                        return <div key={element.display} className="checkbox">
                            <label>
                                <input type="checkbox" checked={element.checked} onChange={() => handleCheck(index)} />
                                <span>{element.display}</span>
                            </label>
                        </div>
                    })
                }
            </div>

            <div className="surveyQ">
                <h4 className="surveyHeader">How likely are you to recommend R-silience to a colleague or friend?</h4>
                <p className="surveysub" style={{ color: recommendError ? "red" : "black" }}>(Required question or you may skip the survey)</p>
                <div id="surveySlider">
                    <div className="buttons">
                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 0 ? "selected" : ""}`}
                                onClick={() => setRecommend(0)}
                            >
                                0
                            </button>
                            <p>Not likely</p>
                        </div>

                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 1 ? "selected" : ""}`}
                                onClick={() => setRecommend(1)}
                            >
                                1
                            </button>
                        </div>

                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 2 ? "selected" : ""}`}
                                onClick={() => setRecommend(2)}
                            >
                                2
                            </button>
                        </div>

                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 3 ? "selected" : ""}`}
                                onClick={() => setRecommend(3)}
                            >
                                3
                            </button>
                        </div>

                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 4 ? "selected" : ""}`}
                                onClick={() => setRecommend(4)}
                            >
                                4
                            </button>
                        </div>

                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 5 ? "selected" : ""}`}
                                onClick={() => setRecommend(5)}
                            >
                                5
                            </button>
                        </div>

                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 6 ? "selected" : ""}`}
                                onClick={() => setRecommend(6)}
                            >
                                6
                            </button>
                        </div>

                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 7 ? "selected" : ""}`}
                                onClick={() => setRecommend(7)}
                            >
                                7
                            </button>
                        </div>

                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 8 ? "selected" : ""}`}
                                onClick={() => setRecommend(8)}
                            >
                                8
                            </button>
                        </div>

                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 9 ? "selected" : ""}`}
                                onClick={() => setRecommend(9)}
                            >
                                9
                            </button>
                        </div>

                        <div className="buttonContainer">
                            <button className={`sliderNum ${recommend === 10 ? "selected" : ""}`}
                                onClick={() => setRecommend(10)}
                            >
                                10
                            </button>
                            <p>Most likely</p>
                        </div>
                    </div>
                </div>
            </div>

            <Button
                text="Submit"
                onClick={() => surveySubmit()}
            />

            <button className="dismiss"
                onClick={() => {
                    const dismissedTime = new Date().toISOString()
                    if (user) {
                        update(ref(database, `users/${user.uid}`), {
                            surveyDateDismissed: dismissedTime,
                            surveyCompleted: false
                        })
                            .then(() => {
                                // state if signed in
                                console.log("survey skipped by user", user.uid);
                                setSurveyCompleted(false)
                                setSurveyDateDismissed(dismissedTime)
                                props.navAfter();
                            });
                    } else {
                        // local storage if not signed in
                        localStorage.setItem("surveyDateDismissed", dismissedTime);
                        localStorage.setItem("surveyCompleted", "false");
                        props.navAfter();
                    }
                }}
            >
                Skip survey without answering
            </button>
        </div>
    </>

}