import { useState, useEffect, useContext, useCallback } from "react";
import {
  Stack,
  Box,
  Heading,
  Stat,
  StatNumber,
  StatLabel,
  Text,
  Input,
  List,
  ListItem,
  useTheme,
} from "@chakra-ui/react";
import { PieChart } from "react-minimal-pie-chart";
import { FirebaseAppContext } from "../../contexts/firebase";
import { ref, onValue } from "firebase/database";

export const SurveysTab = () => {
  const { database, databaseKey } = useContext(FirebaseAppContext);

  const { rblue } = useTheme().colors;
  const [surveyData, setSurveyData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [firstDate, setFirstDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 90))
      .toISOString()
      .split("T")[0]
  ); // 90 days ago
  const [secondDate, setSecondDate] = useState(new Date().toISOString().split("T")[0]);
  const [netPromoterScore, setNetPromoterScore] = useState("--");
  const [chartData, setChartData] = useState([
    {
      key: "friendOrColleague",
      title: "Friend or colleague",
      value: 0,
      color: "lightsteelblue",
    },
    { key: "conference", title: "Conference", value: 0, color: rblue[600] },
    {
      key: "flyerOrPostCard",
      title: "Flyer or post card",
      value: 0,
      color: "#edd36a",
    },
    { key: "houseOwner", title: "House owner", value: 0, color: rblue[300] },
    { key: "phone", title: "Phone", value: 0, color: "darkgray" },
    { key: "socialMedia", title: "Social Media", value: 0, color: rblue[800] },
  ]);

  // fetch data, and set surveyData and filteredData
  useEffect(() => {
    if (surveyData.length === 0) {
      const tempArray = [];
      onValue(
        ref(database, "surveyResponses"), // name of the surveys table in Firebase
        (snapshot) => {
          snapshot.forEach((item) => {
            const childData = { ...item.val(), key: item.key };
            tempArray.push(childData);
          });
          setSurveyData(tempArray);
          setFilteredData(tempArray);
        },
        { onlyOnce: true },
        (error) => {
          console.log(error.message);
        }
      );
    }
  }, [database, databaseKey, surveyData]);

  // set new data based on date range
  useEffect(() => {
    const firstDateMs = new Date(firstDate).getTime();
    const secondDateMs = new Date(secondDate).getTime();
    const filteredByDate = surveyData.filter((item) => {
      const date = new Date(item.date).getTime(); // convert date to ms
      return date >= firstDateMs && date <= secondDateMs;
    });
    setFilteredData(filteredByDate);
  }, [firstDate, secondDate, surveyData]);

  const calculateNetPromoterScore = useCallback(() => {
    const promoters = filteredData.filter(
      (item) => item?.recommend >= 9
    ).length;
    const detractors = filteredData.filter(
      (item) => item?.recommend <= 6
    ).length;
    const total = filteredData.length;
    const nps = ((promoters - detractors) / total) * 100;
    setNetPromoterScore(nps.toFixed(1).toString());
  }, [filteredData]);

  const tallySurveysForPieChart = useCallback(() => {
    const tally = {
      friendOrColleague: 0,
      conference: 0,
      flyerOrPostCard: 0,
      houseOwner: 0,
      phone: 0,
      socialMedia: 0,
    };
    filteredData.forEach((item) => {
      !!item?.friendOrColleague && tally.friendOrColleague++;
      !!item?.conference && tally.conference++;
      !!item?.flyerOrPostCard && tally.flyerOrPostCard++;
      !!item?.houseOwner && tally.houseOwner++;
      !!item?.phone && tally.phone++;
      !!item?.socialMedia && tally.socialMedia++;
    });
    setChartData(
      chartData.map((item) => {
        return {
          ...item,
          value: tally[item.key],
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  useEffect(() => {
    calculateNetPromoterScore();
    tallySurveysForPieChart();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredData]);

  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-around"}
        textAlign={"center"}
        marginBottom={"3rem"}
      >
        <Box display={"flex"} flexDir={"column"}>
          <Heading fontFamily={"Georgia"} color={"rblue.600"}>
            Net Promoter Score
          </Heading>
          <Box display={"flex"} pt={"20%"}>
            <Stat>
              <StatNumber
                fontWeight={"black"}
                fontSize={"6rem"}
                fontFamily={"Georgia"}
                color={"rblue.800"}
              >
                {netPromoterScore !== "NaN" ? netPromoterScore : "--"}%
              </StatNumber>
              <StatLabel fontSize={'1rem'}>{filteredData.length} responses</StatLabel>
            </Stat>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDir={"column"}
          alignItems={"center"}
          gap={"2rem"}
        >
          <Heading fontFamily={"Georgia"} color={"rblue.600"}>
            How did you hear about us?
          </Heading>
          <Stack direction={"row"} gap={5}>
            <Box flex={1}>
              <PieChart
                data={chartData}
                lineWidth={60}
                // startAngle={-45}
                // segmentsShift={(segmentIndex) => number}
              />
            </Box>
            <List textAlign={"left"}>
              {chartData
                .sort((a, b) => b.value - a.value)
                .map((item) => (
                  <ListItem key={item.color}>
                    <Text color={item.color} fontWeight={"semibold"}>
                      {item.title} - {item.value}
                    </Text>
                  </ListItem>
                ))}
            </List>
          </Stack>
        </Box>
      </Stack>
      <Box
        display={"flex"}
        // bg={"blackAlpha.50"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"2rem"}
      >
        {/* .value gives YYYY-MM-DD */}
        {/* .valueAsDate gives UTC */}
        {/* .defaultValue gives ms since epoch */}
        <Input
          type={"date"}
          size={"lg"}
          width={"fit-content"}
          variant={"flushed"}
          value={firstDate}
          onChange={(e) => setFirstDate(e.target.value)}
        />
        <Text>to</Text>
        <Input
          type={"date"}
          size={"lg"}
          width={"fit-content"}
          variant={"flushed"}
          value={secondDate}
          onChange={(e) => setSecondDate(e.target.value)}
        />
      </Box>
    </>
  );
};
