import React, { useLayoutEffect, useState } from 'react';
import './SearchFilters.css';
import DropdownFRState from '../../formComponents/dropdown/DropdownFRState';
import InputFRState from '../../formComponents/inputText/InputFRState';
import { TbCheck, TbAlertCircle } from "react-icons/tb";
import { isMobile, isSafari, isChrome, isFirefox } from 'react-device-detect';
import { states } from '../../../utils/states';


export default function SearchFilters(props) {

    // lock body scroll when visible
    useLayoutEffect(() => {
        if (props.filtersVisible) {
            document.body.classList.add("noscroll");
        }
        return () => document.body.classList.remove("noscroll");
    }, [props.filtersVisible])
    
    const distanceOptions = [
        { placeholder: "Max Distance" },
        { label: "5 Miles", value: 5 },
        { label: "10 Miles", value: 10 },
        { label: "25 Miles", value: 25 },
        { label: "30 Miles", value: 30 },
        { label: "Any Distance", value: 100000 }
    ]

    const narrOptions = [
        { placeholder: "Desired NARR Level" },
        { label: "I", value: 1, displayText: "Peer run house" },
        { label: "II", value: 2, displayText: "House manager or peer run house" },
        { label: "III", value: 3, displayText: "Administrative oversight of service provision" },
        { label: "IV", value: 4, displayText: "Clinical and administrative oversight of service provision" },
    ]

    const matOptions = [
        { placeholder: "Is MAT support required?" },
        { label: "Accepted, all forms", value: 1 },
        { label: "Some forms accepted, ask for details", value: 2 },
        { label: "Vivitrol accepted", value: 3 },
        { label: "Not accepted", value: 0 }
    ]

    const sortDistanceOptions = [
        { placeholder: "Sort Distance" },
        { label: "Closest to Furthest", value: "close" },
        { label: "Furthest to Closest", value: "far" }
    ]

    const sortBookmarksOptions = [
        { placeholder: "Sort Bookmarks" },
        { label: "Only Show Bookmarked Houses", value: "onlyBookmarks" },
        { label: "Prioritize Bookmarked Houses", value: "prioritizeBookmarks" },
        { label: "Don't Prioritize Bookmarked Houses", value: "none" }
    ]

    const [minBoxVal, setMinBoxVal] = useState("");
    const [maxBoxVal, setMaxBoxVal] = useState("");

    const onChangeCheckbox = (key, value) => {
        props.onChangeFilters(new Map(props.filters.set(key,
            { ...props.filters.get(key), value: value })));
    }

    const updateCost = (value, change) => {
        change === "min" ?
            props.onChangeFee({ ...props.weeklyFee, min: value })
            : props.onChangeFee({ ...props.weeklyFee, max: value });
    }

    return (
        <>
            <div className={props.filtersVisible ? "backgroundOverlayFilters filtersBackAniIn" : "backgroundOverlayFilters filtersBackAniOut"}
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", pointerEvents: props.filtersVisible ? "auto" : "none", }}
                onClick={() => { props.onSearch(false) }}
            />

            <div className='backgroundOverlayFilters'>
                <div id="filter-container" className={props.filtersVisible ? 'filter-container filtersAniIn' : "filter-container filtersAniOut"}>

                    <div id="filter-header">

                        <p id="reset-button" className='filter-header-button'
                            onClick={(e) => {
                                e.preventDefault();
                                setMinBoxVal("");
                                setMaxBoxVal("");
                                props.onReset();
                            }}>Reset</p>

                        <p id='filter-header-results-label'>{props.filteredData.length} Results</p>

                        <p className='filter-header-button'
                            onClick={(e) => {
                                e.preventDefault();
                                props.onSearch(false);
                            }}>Search
                        </p>

                    </div>

                    <div id='filter-content' style={{
                        paddingBottom: isMobile ?
                            isSafari ? "105px" : isChrome ? "135px"
                                : isFirefox ? "25px" : "145px" : "25px"
                        /* if it's mobile and none of the browsers listed, I set to 145px 
                        to try to make sure everything is visible. */
                    }}>

                        <div className="filter-heading">Distance</div>

                        <DropdownFRState
                            value={props.distance}
                            onChange={props.onChangeDistance}
                            optionValues={distanceOptions}
                            disabled={!(props.usedLocation?.latitude && props.usedLocation?.longitude)}
                        />

                        <div className='filter-heading'>State</div>

                        <DropdownFRState
                            value={props.state}
                            onChange={props.onChangeState}
                            optionValues={states}
                            disabled={false}
                        />

                        {props.usedLocation?.latitude && props.usedLocation?.longitude ?
                            null
                            :
                            <div className="dropWarning" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <TbAlertCircle className='dropWarningIcon' />
                                <div>Search a location to filter by distance</div>
                            </div>
                        }

                        <div className='filter-heading'>National Alliance of Recovery Residences</div>
                        <DropdownFRState
                            value={props.narr}
                            onChange={props.onChangeNARR}
                            optionValues={narrOptions}
                        />
                        {   // display description text of narr level if one is selected
                            props.narr ?
                                <p>{narrOptions[parseInt(props.narr)].displayText}</p>
                                : null
                        }

                        <div className="filter-heading">Medication-Assisted Treatment (MAT)</div>

                        <DropdownFRState
                            value={props.mat}
                            onChange={props.onChangeMAT}
                            optionValues={matOptions}
                        />

                        <div className='filter-heading'>Weekly Fee</div>

                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>

                            <InputFRState
                                type='number'
                                value={minBoxVal}
                                onChange={(e) => setMinBoxVal(e.target.value)}
                                onBlur={() => updateCost(minBoxVal, "min")}
                                width="100px"
                                label={"Min"}
                            />

                            <span style={{ marginLeft: "35px", marginRight: "35px" }}> – </span>

                            <InputFRState
                                type='number'
                                value={maxBoxVal}
                                onChange={(e) => setMaxBoxVal(e.target.value)}
                                onBlur={() => updateCost(maxBoxVal, "max")}
                                width="100px"
                                label={"Max"}
                            />

                        </div>

                        <div className='filter-heading'>Sort Your Search</div>
                        <DropdownFRState
                            value={props.sortDistance}
                            optionValues={sortDistanceOptions}
                            onChange={props.onChangeSortDistance}
                        />

                        <DropdownFRState
                            margin="35px 0px 0px 0px"
                            value={props.sortBookmarks}
                            optionValues={sortBookmarksOptions}
                            onChange={props.onChangeSortBookmarks}
                        />

                        {
                            // Render checkboxes from Map
                            Array.from(props.filters).map((item) =>
                                item[1] === "HEADING" ?
                                    <div className='filter-heading' key={item[0]}>
                                        <label className="filter-heading">{item[0]}</label>
                                    </div>
                                    :
                                    // Semantically make this a <label> with an <input type="checkbox">
                                    <div
                                        onClick={() => onChangeCheckbox(item[0], !item[1].value)}
                                        key={item[0]} className='checkbox-container' style={{ paddingTop: item[1].first ? "0px" : null, }}>
                                        <TbCheck className={item[1].value ? 'checkedIcon' : "uncheckedIcon"} />
                                        {item[1].title}
                                    </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}