import InputFR from "../../components/formComponents/inputText/InputFR"
import { TbAlertCircle } from 'react-icons/tb';
import '../signUp/signUp.css'

export default function NewEmail(props) {
    
    return <div>
            <div className={props.errorText ? 'warning error' : 'hideEmailError'}>
                <TbAlertCircle className='errorIconsAuth' />
                <div>
                {props.errorText}
                </div>
            </div>
            <InputFR
                margin={props.error ? "20px 0px 0px 0px" : "30px 0px 0px 0px"}
                label="New Email"
                error={props.error}
                //inputRef={props.emailRef}
                blur={(value) => props.blurEmail(value)}
            />
        </div>;
}