import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Box,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useState, useEffect, useContext, useRef } from "react";
import { ref, onValue, update } from "firebase/database";
import { FirebaseAppContext } from "../../contexts/firebase";
import { RecentContactModal } from "./RecentContactModal";

export const HousesTab = () => {
  const { database, databaseKey } = useContext(FirebaseAppContext);

  const [providersList, setProvidersList] = useState(new Set()); // this is the set of fetched houses...
  const [filteredData, setFilteredData] = useState([]); // ...filtered/searched houses
  const [sortedData, setSortedData] = useState([]); // ...sorted houses (ascending/descending), and the ones being displayed
  const [sortConfig, setSortConfig] = useState({
    key: "title",
    direction: "asc",
  });

  // Fetch data from Firebase
  useEffect(() => {
    if (providersList.size === 0) {
      const tempSet = new Set();
      onValue(
        ref(database, databaseKey),
        (snapshot) => {
          snapshot.forEach((item) => {
            const childData = { ...item.val(), key: item.key };
            if (!tempSet.has(childData)) {
              tempSet.add(childData);
            }
          });
          setProvidersList(new Set(tempSet));
          const dataArray = Array.from(tempSet);
          setFilteredData(dataArray);
          setSortedData(dataArray);
        },
        { onlyOnce: true },
        (error) => {
          console.log(error.message);
        }
      );
    }
  }, [providersList, filteredData, database, databaseKey]);

  // calls sorting function
  useEffect(() => {
    const sorted = sortData(filteredData, sortConfig);
    setSortedData(sorted);
  }, [filteredData, sortConfig]);

  // handle each column sort direction
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  // sorting function
  // checks for date properties, then booleans, then strings
  const sortData = (data, { key, direction }) => {
    return [...data].sort((a, b) => {
      const valueA = a[key] ?? ""; // assign value if property is not on object
      const valueB = b[key] ?? "";
      if (
        key === "publishedAt" ||
        key === "editedAt" ||
        key === "contactDate"
      ) {
        const dateA = !!valueA ? new Date(valueA) : new Date(0); // if no date, set to epoch
        const dateB = !!valueB ? new Date(valueB) : new Date(0);
        return direction === "asc" ? dateA - dateB : dateB - dateA;
      } else if (key === "pending" || key === "isSuspended") {
        return direction === "asc" ? valueA - valueB : valueB - valueA; // this is a boolean
      } else {
        return direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    });
  };
  // conditionally renders an arrow if the column is being sorted
  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
      return <span>{sortConfig.direction === "asc" ? "▲" : "▼"}</span>;
    }
    return <span style={{ opacity: 0 }}>▲</span>; // to keep fixed column width, perhaps not ideal
  };

  // this is the communication between the child modal component to:
  // 1. update the 2 contact fields
  // 2. update the view
  // 3. reset the modal values for next use
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalValues, setModalValues] = useState({
    name: "",
    contactDate: "",
    contactAdmin: "",
    itemKey: "",
  });
  const handleModalOpen = (name, contactDate, contactAdmin, itemKey) => {
    setModalValues({ name, contactDate, contactAdmin, itemKey });
    setIsModalOpen(true);
  };
  const handleSave = ({ contactDate, contactAdmin }) => {
    setFilteredData((prevData) =>
      prevData.map((house) =>
        house.key === modalValues.itemKey
          ? { ...house, contactDate, contactAdmin }
          : house
      )
    );
    // reset modal values, else the modal's prefill values will be the last saved values
    setModalValues({
      name: "",
      contactDate: "",
      contactAdmin: "",
      itemKey: "",
    });
  };

  const timeoutRef = useRef(null);
  const handleSearch = (searchTerm) => {
    clearTimeout(timeoutRef.current); // clear previous timeout
    timeoutRef.current = setTimeout(() => {
      const filtered = Array.from(providersList).filter((house) => {
        const { title, creatorEmail } = house;
        return (
          title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          creatorEmail?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredData(filtered);
    }, 200); // set a debounce of 200ms
  };

  const handleSuspension = async (key, isSuspended) => {
    try {
      const itemRef = ref(database, `${databaseKey}/${key}`);
      await update(itemRef, { isSuspended: isSuspended });
      setFilteredData((prevData) =>
        prevData.map((house) =>
          house.key === key ? { ...house, isSuspended } : house
        )
      );
      console.log(
        isSuspended ? "House suspended" : "House unsuspended",
        " successfully"
      );
    } catch (error) {
      console.error("Error updating house: ", error);
    }
  };

  return (
    <>
      <RecentContactModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        {...modalValues}
        onSave={handleSave}
      />
      <Box justifyContent={"space-between"} marginY={"1rem"}>
        <Input
          type={"search"}
          w={"40%"}
          variant={"filled"}
          placeholder={"search by house or email..."}
          bg={"rblue.100"}
          color={"rblue.600"}
          fontSize={"24px"}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Box>
      <TableContainer>
        <Table variant={"striped"} colorScheme={"rblue"} size={"sm"}>
          <Thead>
            <Tr>
              <Th onClick={() => handleSort("isSuspended")} pl={5}>
                <Tooltip
                  label="suspension status"
                  aria-label="Is Suspended Tooltip"
                  background="rblue.700"
                >
                  <ViewIcon />
                </Tooltip>
              </Th>
              <Th onClick={() => handleSort("title")} pl={0}>
                House Name {renderSortArrow("title")}
              </Th>
              <Th onClick={() => handleSort("creatorEmail")}>
                Owner Email {renderSortArrow("creatorEmail")}
              </Th>
              <Th onClick={() => handleSort("publishedAt")}>
                Date Added {renderSortArrow("publishedAt")}
              </Th>
              <Th onClick={() => handleSort("pending")}>
                Review Status {renderSortArrow("pending")}
              </Th>
              <Th onClick={() => handleSort("editedAt")}>
                Last Edit {renderSortArrow("editedAt")}
              </Th>
              <Th onClick={() => handleSort("contactDate")}>
                Last Contact {renderSortArrow("contactDate")}
              </Th>
              <Th onClick={() => handleSort("contactAdmin")}>
                Contacted By {renderSortArrow("contactAdmin")}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedData.map((house) => {
              const {
                key,
                isSuspended = false,
                title: name,
                creatorEmail: ownerEmail,
                publishedAt: dateAdded,
                pending = false,
                editedAt: lastEditDate,
                contactDate,
                contactAdmin,
              } = house;

              const lastEditDateFormatted = lastEditDate
                ? new Date(lastEditDate).toLocaleDateString()
                : "–––";
              const dateAddedFormatted = dateAdded
                ? new Date(dateAdded).toLocaleDateString()
                : "–––";

              return (
                <Tr key={key}>
                  <Td p={0}>
                    <Button
                      aria-label={"Toggle suspend house"}
                      onClick={() => handleSuspension(key, !isSuspended)}
                      bg={"none"}
                      _hover={"none"}
                      p={0}
                      ml={2}
                      justifyItems={"center"}
                    >
                      {isSuspended ? (
                        <ViewOffIcon color={"rblue.700"} />
                      ) : (
                        <ViewIcon color={"rblue.300"} />
                      )}
                    </Button>
                  </Td>
                  <Td
                    pl={0}
                    maxW={"14rem"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                  >
                    {name}
                  </Td>
                  <Td>{ownerEmail}</Td>
                  <Td>{dateAddedFormatted}</Td>
                  <Td>{pending ? "Pending" : "Approved"}</Td>
                  <Td>{lastEditDateFormatted}</Td>
                  <Td>
                    <Button
                      fontSize={"sm"}
                      fontWeight={"normal"}
                      variant={"ghost"}
                      bg={"rblue.50"}
                      w={"100%"}
                      onClick={() =>
                        handleModalOpen(name, contactDate, contactAdmin, key)
                      }
                    >
                      {contactDate || "–––"}
                    </Button>
                  </Td>
                  <Td>
                    <Button
                      fontSize={"sm"}
                      fontWeight={"normal"}
                      variant={"ghost"}
                      bg={"rblue.50"}
                      w={"100%"}
                      onClick={() =>
                        handleModalOpen(name, contactDate, contactAdmin, key)
                      }
                    >
                      {contactAdmin || "–––"}
                    </Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
