import Button from "../../Button/Button"
import "./Disclaimer.css"

export default function Disclaimer(props) {

    const titles = {
        search: "(Individuals Looking for a House)",
        provider: "(Recovery House Managers)",
    }

    const text = {
        search: 'Miami University has created R-silience (the “App”) to make it easier for people to find drug and alcohol recovery housing that meets their needs.  By utilizing the App, you hereby acknowledge that Miami University has not vetted any of the facilities posted through the App, and that Miami University does not make any guarantees, representations, or warranties regarding the condition, safety, or fitness of any such facilities. Therefore, it is your responsibility to take all reasonable steps to investigate and research a recovery house facility to determine whether it is a suitable and safe environment for you.  To the fullest extent permitted under applicable law, by using the App, you hereby agree to assume the entire risk arising out of or related to your use of the App and any of its content, including your experiences at any recovery house or any interactions you have with recovery houses found through the App.',
        provider: 'Miami University has created R-silience (the “App”) to make it easier for people to find drug and alcohol recovery housing that meets their needs.  By utilizing the App, you hereby acknowledge that Miami University has not vetted any of the individuals that seek recovery housing through the App, and that Miami University does not make any guarantees, representations, or warranties regarding the background, safety, or security of any persons that are using the App. Therefore, it is your responsibility to take all reasonable steps to investigate and research a potential resident who found your house via the App in order to determine whether it is suitable and safe for you to invite them to your residence. To the fullest extent permitted under applicable law, by using the App, you hereby agree to assume the entire risk arising out of or related to your use of the App and any of its content, including your experiences with any other people that you connect with through the App.',
        button: 'I understand my responsibilities in using the App',
    }

    return <div id="disclaimerContainer">
        <img src={require("../../../imgs/RSLogo.png")} className="appLogo-disclaimer" alt="" />
        <h3 style={{ textAlign: "center" }}>
            Assumption of Risk
            <br />
            {titles[props.type]}
        </h3>
        <p>{text[props.type]}</p>

        <div className="disclaimerButton">
            <Button
                text={text.button}
                onClick={props.onAgree}
                size="large"
            />
        </div>

    </div>
}