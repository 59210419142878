import InputFR from '../../components/formComponents/inputText/InputFR';
import { TbAlertCircle } from 'react-icons/tb';
import '../signUp/signUp.css'
import { useRef } from 'react';

export default function EnterCurrentPassword(props) {

    const password = useRef();

    return <div>
            <div className={props.error ? 'warning error emailError' : 'hideEmailError'} style={{ marginBottom: "20px" }}>
                <TbAlertCircle className='errorIconsAuth' />
                <div>
                    {props.error && "Incorrect Password"}
                </div>
            </div>
            <InputFR
                type="password"
                inputRef={password}
                error={props.error}
                label="Current Password"
                blur={(value) => props.blurPassword(value)}
            />
        </div>;
}