import "./locationScreenButtons.css";
import LocationScreenButton from "./locationScreenButton/LocationScreenButton";
import { useState, useContext } from "react";
import { FirebaseAppContext } from "../../../contexts/firebase";
import { ref, update } from "firebase/database";
import { TbEye, TbEyeOff } from "react-icons/tb";

const LocationScreenButtons = (props) => {
  const { database, databaseKey, admin } = useContext(FirebaseAppContext);
  const [isSuspended, setIsSuspended] = useState(props?.isSuspended ?? false);

  const handleSuspension = async () => {
    try {
      const itemRef = ref(database, `${databaseKey}/${props.selectedKey}`);
      await update(itemRef, { isSuspended: !isSuspended });
      setIsSuspended(!isSuspended);
    } catch (error) {
      console.error("Error updating house: ", error);
    }
  };

  return (
    <div className="locationScreenButtons">
      {props.websiteLink ? (
        <a
          className="linkStyle"
          target="_blank"
          href={props.websiteLink}
          rel="noreferrer"
        >
          <LocationScreenButton label={"Website"} />
        </a>
      ) : (
        <LocationScreenButton disabled={true} label={"Website"} />
      )}

      {
        // hide bookmarks if prop is not passed
        props.bookmarkedHousesValue && (
          <LocationScreenButton
            bookmarkedHousesValue={props.bookmarkedHousesValue}
            setBookmarkedHousesValue={props.setBookmarkedHousesValue}
            label={"Bookmark"}
            selectedKey={props.selectedKey}
          />
        )
      }
      {admin && (
        <button
          label={"Toggle suspension"}
          onClick={handleSuspension}
          className="locationScreenButton"
        >
          {isSuspended ? <TbEye className="icon" /> : <TbEyeOff className="icon"/> }
          {isSuspended ? "Un-suspend house" : "Suspend house"}
        </button>
      )}
    </div>
  );
};
export default LocationScreenButtons;
