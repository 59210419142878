/**
 * This function runs the Google Places API search.
 * @param {String} text The text from the search 
 * @returns An object with the latitude/longitude of the Promise or null if zero results.
 */

export default async function locationApiSearch(text) {

    const newLocation = await fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(text) + '&key=AIzaSyAycEYH2p7z2r7IrQkra0atRCCHccpan1o')
    .then((response) => {   // promise fulfilled, even if no results
        return response.json(); 
    })
    .catch((error) => {     // invalid searches don't catch errors
        alert("There was an error in searching this location. Please try again."); 
        console.log(error.message);
    });

    // conditional of the API, return information if present
    return newLocation.status === 'ZERO_RESULTS' ? null : {
        latitude: newLocation.results[0].geometry.location.lat,
        longitude: newLocation.results[0].geometry.location.lng
    };
}