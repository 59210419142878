/**
 * Gets the user's location using the HTML geolocation API.
 * @returns an object containing the longitude and latitude of the location if permission is allowed, null if not
 */

export default function getLocation() {
    
    return new Promise((res, rej) => {
        navigator.geolocation.getCurrentPosition(res, rej);
    })
}