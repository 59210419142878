import InputFR from "../../components/formComponents/inputText/InputFR";
import { TbAlertCircle } from 'react-icons/tb';
import '../signUp/signUp.css'

export default function NewPassword(props) {

    return <div>
        <div className={props.errorText ? 'warning error' : 'warning'}>
            {props.errorText && <TbAlertCircle className='errorIconsAuth' />}
            <div>
                {props.errorText || "New password must be at least six characters"}
            </div>
        </div>

        <InputFR
            margin="5px 0px 0px 0px"
            type="password"
            error={props.error}
            label="New Password"
            //inputRef={props.passwordRef}
            blur={(value) => props.blurPassword(value)}
        />

        <InputFR
            margin="20px 0px 0px 0px"
            type="password"
            error={props.error}
            label="Confirm Password"
            //inputRef={props.confirmRef}
            blur={(value) => props.blurConfirm(value)}
        />
    </div>;
}