import "./RadioNARR.css";
import React, { useState } from "react";

/**
 * Renders radio buttons letting a house publisher select the NARR level
 * of their house, either levels 1 through 4 or unknown.
 * @param {*} props - React props; starting value for component and if there's an error
 * @returns React component of 5 labeled radio buttons
 */
const RadioNARR = (props) => {
    
    // hard coded NARR level options as shown in R-silience app
    const options = [
        { value: 1, text: "Level I: Peer run house" },
        { value: 2, text: "Level II: House manager or senior peer run house" },
        { value: 3, text: "Level III: Administrative oversight of service provision" },
        { value: 4, text: "Level IV: Clinical and administrative oversight of service provision" },
        { value: 0, text: "Unknown" }
    ]

    const [selected, setSelected] = useState(props.startValue === 0 ? 4 : props.startValue)

    return <div style={{ marginTop: 10, marginBottom: 20 }}>
        {
            options.map((element, index) => {
                return <label key={element.value} className="inputLabel" style={{ color: props.error && !selected ? "red" : "black"}}>
                    <input
                        className={`radio ${props.error ? "radio-error" : ""}`}
                        id={`narr-${element.value}`}
                        type="radio"
                        value={element.value}
                        // only state update if needed
                        onChange={() => { if (!selected) setSelected(true) }}
                        name="narr"
                        defaultChecked={props.startValue === ++index || (props.startValue === undefined && index === 4)}
                    />
                    <span
                        className={`customRadio ${props.error && !selected ? "radioError" : ""} 
                            ${selected === index ? "selected" : ""}`}>
                        <span className="radioCircle" />
                    </span>
                    {element.text}<br />
                </label>
            })
        }
    </div>
}

export default RadioNARR;