import "./Button.css";

/*
    -- Color and size props supported (check CSS): --

    Colors: red, blue - defaults blue
        - red is associated with destructive actions on AlertPops
    Sizes: small, medium, large - defaults medium
*/
export default function Button(props) {
    return <button
        className={`button button-${props.color || "blue"} button-${props.size || "medium"}`}
        onClick={() => props.onClick()}
        style={{ width: props.width || "100%" }}
    >
        {props.text}
    </button>
}