import getDistance from "../getDistance";
import roundNum from "../roundNum";

export default function runSearch(houses, distance, fee, filters, location, mat, bookmarks, howFilter, filterBookmarks, narr, state) {
    let results = Array.from(houses);

    if (location?.longitude && location?.longitude) {
        results = results.filter(place => roundNum(getDistance(place.address.location, location) <= distance));
    }

    if (!!fee.min) {
        results = results.filter(place => fee.min <= place.weeklyFee);
    }

    if (!!fee.max) {
        results = results.filter(place => fee.max >= place.weeklyFee);
    }

    if (mat) {
        results = results.filter(place => Number(place.mat) === Number(mat));
    }

    if (narr) {
        results = results.filter(place => place.searchFilters.narr === Number(narr));
    }

    if (state) {
        results = results.filter(place => place.address.state === state);
    }

    Array.from(filters).forEach(element => {
        if (element[1] !== "HEADING" && element[1].value) {
            if (!!element[1].tag) {
                results = results.filter(house => house.gender[element[0]]);
            } else {
                if (element[0] === "certified") {
                    results = results.filter(house => (
                        house.searchFilters.orhCertified
                        || house.searchFilters.carfAccredited
                        || house.searchFilters.oxfordHouseCertified
                        || house.searchFilters.narrAccredited
                        || (house.searchFilters.otherCertOrAcredit !== undefined && house.searchFilters.otherCertOrAcredit !== "0")
                    ));
                } else {
                    results = results.filter(house => house.searchFilters[element[0]]);
                }
            }
        }
    });

    return sort(results, bookmarks, location, howFilter, filterBookmarks);

}

const sort = (houses, bookmarks, location, howFilter, filterBookmarks) => {
    let sorting = houses;

    // distance
    if (location?.latitude && location?.latitude) {
        sorting = howFilter === "close" ?
            sorting.sort((a, b) => getDistance(a.address.location, location) - getDistance(b.address.location, location))
            : sorting.sort((a, b) => getDistance(b.address.location, location) - getDistance(a.address.location, location));
    }

    // bookmarks
    if (filterBookmarks === "prioritizeBookmarks") {
        const bookmarked = sorting.filter(house => bookmarks.indexOf(house.key) >= 0);
        const notBookmarked = sorting.filter(house => bookmarks.indexOf(house.key) === -1);
        sorting = [...bookmarked, ...notBookmarked];
    } else if (filterBookmarks === "onlyBookmarks") {
        sorting = sorting.filter(house => bookmarks.indexOf(house.key) >= 0);
    }

    return sorting;
}