import React from 'react';
import HouseItem from '../houseItem/HouseItem';
import './searchResults.css'

export default function SearchResults(props) {
    return (
            <div className="results">
                {
                    props.results.map((item) =>
                        <HouseItem
                            setDistanceDisplay={props.setDistanceDisplay}
                            bookmarkedHouses={props.bookmarkedHouses}
                            setBookmarkedHouses={props.setBookmarkedHouses}
                            setSelectedHouse={props.setSelectedHouse}
                            setHouseInfo={props.setHouseInfo}
                            item={item}
                            title={item.title}
                            image1={item.image.imageUrl1}
                            phoneNumber={item.phoneNumber}
                            houseLocation={item.address.location}
                            houseCity={item.address.city}
                            useLocation={props.usedLocation}
                            cost={item.weeklyFee}
                            intakeFee={item.intakeFee}
                            availableBeds={item.availableBeds}
                            zipCode={item.address.zipCode}
                            key={item.key}

                            showBookmark={true}
                        />
                    )
                }
            </div>
    )
}