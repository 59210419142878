import './dropdownFR.css'
import React, { useRef } from 'react';
import { useState } from "react";
import { TbChevronDown } from "react-icons/tb";

const DropdownFR = (props) => {
    //in this case, this is just used to force a rerender once to after the first onBlur to trigger the animation
    const [, setFocusedInput] = useState(props.focusedInput);
    const ref = useRef();

    return (
        <div style={{ margin: props.margin }}/* select elements just don't respect margins properly */>
            <div className='containerDrop' style={{ width: props.width || "100%" }}>
                <select
                    //ref={props.inputRef}
                    ref={ref}
                    defaultValue={props.startValue || ""}
                    onChange={() => { ref.current.blur() }}
                    className="dropForm"
                    style={{ 
                        color: ref.current?.value || props.startValue ? "black" : "transparent", 
                        borderColor: props.error && !ref.current?.value ? "red" : null, 
                        width: "100%" 
                    }}
                    onBlur={() => { setFocusedInput(!props.focusedInput) }}
                    id={props.id}
                >
                    {props.optionValues.map((element, index) => {
                        return (
                            <React.Fragment key={index}>
                                {index === 0 ?
                                    <option style={{ color: "transparent" }} value="" disabled hidden>{element.placeholder}</option>
                                    :
                                    <option style={{ color: "black" }} value={element.value}>{element.label}</option>
                                }
                            </React.Fragment>
                        )
                    })
                    }
                </select>

                <div className={ref.current?.value || props.startValue 
                    ? 'dropLabelAndBackgroundContainerFirst dropLabelAndBackgroundContainerAfterFirst' 
                    : 'dropLabelAndBackgroundContainerFirst'}>

                    {ref.current?.value || props.startValue ?

                        <div className='dropLabel'>
                            {props.optionValues[0].placeholder}
                        </div>
                        :
                        <div className='dropLabelFirst'>
                            {props.optionValues[0].placeholder}
                        </div>
                    }
                </div>

                <div className={ref.current?.value || props.startValue ? 'blockLine blockLineAni' : 'blockLine'}
                >
                    {props.optionValues[0].placeholder}
                </div>

                <div className='iconDropContain'>
                    <TbChevronDown className='iconDrop' />
                </div>
            </div>
        </div>
    )
}
export default DropdownFR;