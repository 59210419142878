import React, { useContext, useState } from 'react';
import { TbUserCircle } from 'react-icons/tb';
import ChangeEmailOrPassword from './ChangeEmailOrPassword';
import { useLocation } from 'react-router-dom';
import EnterCurrentPassword from './EnterCurrentPassword';
import { FirebaseAppContext } from '../../contexts/firebase';

export default function EditInfo(props) {
    const location = useLocation();
    const editMode = location.state?.change || props.change;

    const { user } = useContext(FirebaseAppContext);

    const [password, setPassword] = useState(null);
    const [currentPasswordError, setCurrentPasswordError] = useState(false);

    return (
        <div style={{ width: "100%", padding: "40px", display: "flex", flexDirection: 'column' }}>

            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", alignSelf: "center", marginBottom: "30px", }}>
                <TbUserCircle color='#2a8094' style={{ fontSize: "30px" }} />
                <div style={{ marginLeft: "8px", fontSize: "22px" }}>{user?.email}</div>
            </div>

            <div 
                style={{ marginBottom: props.change === "Password" ? "30px" : "8px", fontSize: "20px", color: "#2a8094", fontWeight: "bold" }}
            >
                Change your {props.change === "Password" ? "password" : "email address"}
            </div>
            {props.change === "Password" ?
                null :
                <div style={{ marginBottom: "30px", fontSize: "14px", fontStyle: "italic" }}>
                    All houses created with the previous email will transfer to the new email.
                </div>}

            <EnterCurrentPassword
                password={password}
                error={currentPasswordError}
                blurPassword={(value) => setPassword(value)}
            />

            <ChangeEmailOrPassword
                setShowItem={props.setShowItem}
                change={editMode}
                currentPassword={password}
                onCurrentPasswordError={setCurrentPasswordError}
            />

        </div>
    );
}