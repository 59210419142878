import "./header.css"
import HeaderButtonNoAuth from "./HeaderButtonNoAuth";
import companyLogo from "../../imgs/RSLogo.png"
import { useState, useContext } from "react";
import { TbUserCircle } from "react-icons/tb";
import NavMenu from "../navMenu/NavMenu"
import ProfileContainer from "../../pages/profileContainer/ProfileContainer";
import { FirebaseAppContext } from "../../contexts/firebase";

export default function Header(props) {

    const { user } = useContext(FirebaseAppContext);

    const [showMenu, setShowMenu] = useState(false);

    const [showChangeEmail, setShowChangeEmail] = useState(false);

    const [showChangePassword, setShowChangePassword] = useState(false);

    return (
        <div>
            <div className="homeHeader">

                <a className="brandingHeader" target="_blank" href={"https://rsilience.com/"} rel="noreferrer">
                    <img className="brandingHeaderImage" src={companyLogo} alt="R-silience Logo" />
                    <h5 className="brandNameHeader">R-silience</h5>
                </a>

                <div style={{ marginRight: "15px" }}>
                    {
                        user?.uid ?
                            <div>
                                <TbUserCircle id="menuButtonIcon" onClick={() => setShowMenu(true)} />
                            </div>
                            :
                            <HeaderButtonNoAuth
                                signInClick={() => { props.setShowAuthPop(true); props.setOnSignIn(true) }}
                                signUpClick={() => { props.setShowAuthPop(true); props.setOnSignIn(false) }}
                            />
                    }
                </div>
            </div>

            <NavMenu
                bookmarkedHouses={props.bookmarkedHouses}
                showChangeEmail={showChangeEmail}
                showChangePassword={showChangePassword}
                auth={user}
                setShowMenu={setShowMenu}
                showMenu={showMenu}
                setShowChangeEmail={setShowChangeEmail}
                setShowChangePassword={setShowChangePassword}
            />

            {
                showChangeEmail && <ProfileContainer
                    setShowItem={setShowChangeEmail}
                    title="Change Email"
                    change="Email"
                />
            }

            {showChangePassword && <ProfileContainer
                setShowItem={setShowChangePassword}
                title="Change Password"
                change="Password"
            />
            }
        </div>

    )
}
