import '../../homeHeaderComponent/header.css'
import './formHeader.css'
import "../../searchComponents/searchFilters/SearchFilters.css"
import { IoChevronBack } from "react-icons/io5";


export default function FormHeader(props) {
    return (
        <div className="formHeader" style={{ maxWidth: props.editHouseScreen ? "none" : null, }}>

            {props.editHouseScreen ?

                <>
                    <IoChevronBack className='backIconEditHouse' onClick={() => props.onClickBack()} />

                    <p id='filter-header-results-label' 
                        style={{ textOverflow: "ellipsis", 
                        fontWeight: 'normal', 
                        width: "40%", whiteSpace: "nowrap", 
                        textAlign: "center", 
                        overflow: "hidden" 
                    }}>
                        Edit a House
                    </p>

                    <IoChevronBack className='backIconEditHouseWhite' />

                </>
                :
                <>
                    <p
                        id="reset-button"
                        className='filter-header-button'
                        style={{ marginLeft: "15px", paddingRight: props.houseTitle | props.saveTitle ? "56.12px" : "55.35px" }}
                        onClick={() => props.onClickCancel()}
                    >
                        Cancel
                    </p>

                    <p id='filter-header-results-label' style={{ 
                        textOverflow: "ellipsis", 
                        width: "40%", 
                        whiteSpace: "nowrap", 
                        textAlign: "center", 
                        overflow: "hidden", 
                        fontSize: 16 
                    }}>
                        {props.houseTitle || props.updateAvailabilityScreen ? "Update Bed Availability" : "Housing Provider Form"}
                    </p>

                    <p
                        className='filter-header-button'
                        style={{ marginRight: "15px", }}
                        onClick={() => { props.onClickSave() }}
                    >
                        {props.houseTitle || props.saveTitle ? "Save Changes" : "Upload House"}
                    </p>
                </>
            }
        </div>
    )
}