import "./locationScreen.css";
import BasicPoints from "../../components/locationScreenComponents/basicPoints/BasicPoints";
import ImageScroll from "../../components/locationScreenComponents/renderImages/ImageScroll";
import LocationScreenButtons from "../../components/locationScreenComponents/locationScreenButtons/LocationScreenButtons";
import Map from "../../components/locationScreenComponents/map/Map";
import InfoPoints from "../../components/locationScreenComponents/infoPoints/InfoPoints";
import Title from "../../components/locationScreenComponents/title/Title";
import {
  TbPhone,
  TbCalendar,
  TbReportMoney,
  TbCheck,
  TbX,
} from "react-icons/tb";
import { HiOutlineBriefcase } from "react-icons/hi";
import {
  TbCircleOff,
  TbSmoking,
  TbBabyCarriage,
  TbCircleX,
  TbPaw,
  TbCircleCheck,
  TbGenderTransgender,
  TbGenderFemale,
  TbGenderMale,
  TbBed,
  TbMail,
  TbHomeEdit,
} from "react-icons/tb";
import { isMobile, isSafari, isChrome, isFirefox } from "react-device-detect";
import { useContext, useLayoutEffect, useState } from "react";
import { FirebaseAppContext } from "../../contexts/firebase";
import { ref, update, set } from "firebase/database";
import { ref as storageRef, deleteObject } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import AlertPop from "../../components/pops/alertPop/AlertPop";
import PopBackground from "../../components/pops/PopBackground";

const LocationScreen = (props) => {
  const { database, databaseKey, storage, user } =
    useContext(FirebaseAppContext);
  const navigate = useNavigate();

  const houseInfo = props.houseInfo;
  const websiteLink =
    houseInfo?.website !== "https://0/" ? houseInfo.website : false;

  const matOptions = [
    "Not accepted",
    "Accepted, all forms",
    "Some forms accepted, ask for details",
    "Vivitrol Accepted",
  ];

  const basicPoints = [
    props.isAdmin && {
      label: "Email",
      value: houseInfo.creatorEmail,
      icon: <TbMail className="basicPointIcons" />,
    },
    props.isAdmin &&
      houseInfo.helperEmail && {
        label: "Helper Creator",
        value: houseInfo.helperEmail,
        icon: <TbHomeEdit className="basicPointIcons" />,
      },
    {
      label: "Phone Number",
      value:
        "(" +
        houseInfo.phoneNumber.substring(0, 3) +
        ") " +
        houseInfo.phoneNumber.substring(3, 6) +
        "-" +
        houseInfo.phoneNumber.substring(6),
      icon: <TbPhone className="basicPointIcons" />,
    },
    {
      label: "Availability",
      value: houseInfo.availableBeds
        ? "Beds Available"
        : "Call for Availability",
      icon: <TbBed className="basicPointIcons" />,
    },
    {
      label: "Weekly Cost",
      value: "$" + houseInfo.weeklyFee,
      icon: <TbCalendar className="basicPointIcons" />,
    },
    {
      label: "Intake Fee",
      value: "$" + houseInfo.intakeFee,
      icon: <TbReportMoney className="basicPointIcons" />,
    },
  ];

  const infoPoints = [
    {
      identify: "title",
      label: "Overview",
    },
    {
      identify: "overview",
      label: houseInfo.overview,
    },

    // NARR level doesn't show if not present in house
    houseInfo.searchFilters.narr
      ? {
          identify: "title",
          label:
            "NARR Level - " +
            (houseInfo.searchFilters.narr === 1
              ? "I"
              : houseInfo.searchFilters.narr === 2
              ? "II"
              : houseInfo.searchFilters.narr === 3
              ? "III"
              : houseInfo.searchFilters.narr === 4
              ? "IV"
              : null),
        }
      : null,
    houseInfo.searchFilters.narr
      ? {
          identify: "overview",
          label:
            houseInfo.searchFilters.narr === 1
              ? "Peer run house"
              : houseInfo.searchFilters.narr === 2
              ? "House manager or peer run house"
              : houseInfo.searchFilters.narr === 3
              ? "Administrative oversight of service provision"
              : houseInfo.searchFilters.narr === 4
              ? "Clinical and administrative oversight of service provision"
              : null,
        }
      : null,
    {
      identify: "title",
      label: "Gender Accommodations",
    },
    {
      identify: "points",
      content: [
        {
          icon: <TbGenderFemale className="infoIcons" />,
          label: houseInfo.gender.female
            ? houseInfo.gender.male
              ? "Females"
              : "Females Only"
            : false,
        },
        {
          icon: <TbGenderMale className="infoIcons" />,
          label: houseInfo.gender.male
            ? houseInfo.gender.female
              ? "Males"
              : "Males Only"
            : false,
        },
        {
          icon: <TbGenderTransgender className="infoIcons" />,
          label: houseInfo.gender.lgbtq ? "LGBTQ+ Affirming" : false,
        },
      ],
    },
    {
      identify: "title",
      label: "Available Recovery Support",
    },
    {
      identify: "points",
      recoverySupport: true,
      onlyOneIcon: true,
      iconGreen: (
        <TbCircleCheck className="infoIcons" style={{ color: "#32A246" }} />
      ),
      content: [
        {
          label: `Medication-Assisted Treatment: ${`${
            houseInfo.mat === true ? matOptions[2] : matOptions[houseInfo.mat]
          }`}`,
          value: houseInfo.mat,
        },
        {
          label: "On-Site 12-Step Program",
          value: houseInfo.searchFilters.step12,
        },
        {
          label: "On-Site House Managers",
          value: houseInfo.searchFilters.houseManagers,
        },
        {
          label: "Professional Mental Health Services",
          value: houseInfo.searchFilters.mentalHealthServices,
        },
        {
          label: "Faith-Based Recovery",
          value: houseInfo.searchFilters.faithBased,
        },
        {
          label: "Employment Assistance",
          value: houseInfo.searchFilters.employmentAssistance,
        },
        {
          label: "Peer Group Meetings",
          value: houseInfo.searchFilters.peerGroupMeetings,
        },

        // certifications don't show if not present
        houseInfo.searchFilters.orhCertified
          ? {
              label: "ORH Certified",
              value: true,
            }
          : null,
        houseInfo.searchFilters.carfAccredited
          ? {
              label: "CARF Accredited",
              value: true,
            }
          : null,
        houseInfo.searchFilters.oxfordHouseCertified
          ? {
              label: "Oxford House Certified",
              value: true,
            }
          : null,
        houseInfo.searchFilters.otherCertOrAcredit !== "0" &&
        houseInfo.searchFilters.otherCertOrAcredit !== undefined
          ? {
              label:
                "Other Certification or Accreditation: " +
                houseInfo.searchFilters.otherCertOrAcredit,
              value: true,
            }
          : null,
      ],
    },
    {
      identify: "title",
      label: "Policies",
    },
    {
      identify: "points",
      content: [
        {
          label: houseInfo.searchFilters.tobaccoUsage
            ? "Allows Tobacco Usage"
            : "Prohibits Tobacco Usage",
          icon: houseInfo.searchFilters.tobaccoUsage ? (
            <TbSmoking className="infoIcons" />
          ) : (
            <div style={{ display: "flex" }}>
              <TbCircleOff className="infoIcons prohibit" />
              <TbSmoking className="infoIcons iconProhibit" />
            </div>
          ),
        },
        {
          label: houseInfo.searchFilters.serviceAnimals
            ? "Allows Service Animals"
            : "Prohibits Service Animals",
          icon: houseInfo.searchFilters.serviceAnimals ? (
            <TbPaw className="infoIcons" />
          ) : (
            <div style={{ display: "flex" }}>
              <TbCircleOff className="infoIcons prohibit" />
              <TbPaw className="infoIcons iconProhibit" />
            </div>
          ),
        },
        {
          label: houseInfo.searchFilters.overnightVisitors
            ? "Allows Overnight Visitors"
            : "Prohibits Overnight Visitors",
          icon: houseInfo.searchFilters.overnightVisitors ? (
            <HiOutlineBriefcase className="infoIcons" />
          ) : (
            <div style={{ display: "flex" }}>
              <TbCircleOff className="infoIcons prohibit" />
              <HiOutlineBriefcase className="infoIcons iconProhibit" />
            </div>
          ),
        },
        {
          label: houseInfo.searchFilters.kidFriendly
            ? "Kid Friendly (Ask for Details)"
            : "Not Kid Friendly",
          icon: houseInfo.searchFilters.kidFriendly ? (
            <TbBabyCarriage className="infoIcons" />
          ) : (
            <div style={{ display: "flex" }}>
              <TbCircleOff className="infoIcons prohibit" />
              <TbBabyCarriage className="infoIcons iconProhibit" />
            </div>
          ),
        },
      ],
    },
    {
      identify: "title",
      label: "Accommodations and Finances",
    },
    {
      identify: "points",
      onlyOneIcon: true,
      iconGreen: (
        <TbCircleCheck className="infoIcons" style={{ color: "#32A246" }} />
      ),
      iconRed: <TbCircleX className="infoIcons" style={{ color: "#D65454" }} />,
      content: [
        {
          label: houseInfo.searchFilters.wheelchairAccessible
            ? "Wheelchair Accessible"
            : "Not Wheelchair Accessible",
          value: houseInfo.searchFilters.wheelchairAccessible,
        },
        {
          label: houseInfo.searchFilters.publicTransport
            ? "Access to Public Transportation"
            : "No Public Transportation Nearby",
          value: houseInfo.searchFilters.publicTransport,
        },
        {
          label: houseInfo.searchFilters.pregnancySupport
            ? "Provides Pregnancy Support"
            : "No Pregnancy Support",
          value: houseInfo.searchFilters.pregnancySupport,
        },
        {
          label: houseInfo.searchFilters.flexiblePayment
            ? "Allows Flexible Payment Options"
            : "No Flexible Payment Options",
          value: houseInfo.searchFilters.flexiblePayment,
        },
        {
          label: houseInfo.searchFilters.medicaid
            ? "Has Payment Assistance or Subsidy Options"
            : "Does Not Have Payment Assistance or Subsidy Options",
          value: houseInfo.searchFilters.medicaid,
        },
      ],
    },
  ];

  // gets all image urls and turns into an array
  const imageArray = Object.values(houseInfo.image);

  const deleteImage = (url) => {
    console.log(url);
    if (url) {
      const imageRef = storageRef(storage, url);

      deleteObject(imageRef)
        .then(() => {
          // image deleted
          console.log("image deleted");
        })
        .catch((error) => {
          console.log(error.code);
        });
    } else {
      //console.log("no image");
      return;
    }
  };

  const [showAcceptPop, setShowAcceptPop] = useState(false);

  // lock body scrolling when visible
  useLayoutEffect(() => {
    document.body.classList.add("noscroll");
    return () => document.body.classList.remove("noscroll");
  }, []);

  return (
    <div className="backgroundOverlay">
      <div className="containerSection">
        <div className="splitContain">
          <div style={{ zIndex: 10000 }}>
            <PopBackground
              visible={showAcceptPop}
              component={
                <AlertPop
                  title="You have accepted this house."
                  subtitle="You will need to proofread the information and send the house to R-silience Staff for approval"
                  type="navgateAwayOptions"
                  onClickLeft={() => {
                    console.log("CLICK");
                    navigate(0, { replace: true });
                  }}
                  leftTitle="Back to Home"
                />
              }
              centered={true}
            />
          </div>

          <div className="infoSide">
            <Title
              setSelectedHouse={props.setSelectedHouse}
              bookmarkedHousesValue={props.bookmarkedHouses}
              title={houseInfo.title}
            />

            <div
              className="infoSideInnerContain"
              style={{
                paddingBottom: isMobile
                  ? isSafari
                    ? "105px"
                    : isChrome
                    ? "130px"
                    : isFirefox
                    ? "35px"
                    : "130px"
                  : "35px",
                /* if it's mobile and none of the browsers listed, I set to 145px to try to make sure everything is visible. */
              }}
            >
              <div className="imageScroll">
                <ImageScroll images={imageArray} />
              </div>

              <LocationScreenButtons
                bookmarkedHousesValue={props.bookmarkedHouses}
                setBookmarkedHousesValue={props.setBookmarkedHouses}
                selectedKey={houseInfo.key}
                websiteLink={websiteLink}
              />

              {props.isAdmin && (
                <div id="admin-approve" className="houseControls">
                  <div
                    className="admin-approvebutton"
                    id="accept"
                    onClick={() => {
                      update(ref(database, `${databaseKey}/${houseInfo.key}`), {
                        pending: false,
                      }).then(() => {
                        navigate(0, { replace: true });
                      });
                    }}
                  >
                    <TbCheck />
                    Approve
                  </div>

                  <div
                    className="admin-approvebutton"
                    id="deny"
                    onClick={() => {
                      deleteImage(houseInfo.image.imageUrl1);
                      deleteImage(houseInfo.image?.imageUrl2);
                      deleteImage(houseInfo.image?.imageUrl3);
                      deleteImage(houseInfo.image?.imageUrl4);
                      deleteImage(houseInfo.image?.imageUrl5);
                      deleteImage(houseInfo.image?.imageUrl6);
                      deleteImage(houseInfo.image?.imageUrl7);
                      deleteImage(houseInfo.image?.imageUrl8);

                      set(ref(database, `${databaseKey}/${houseInfo.key}`), {})
                        .then(() => {
                          // house deleted
                          console.log("house deleted");
                          navigate(0, { replace: true });
                        })
                        .catch((error) => {
                          console.log(error.code);
                        });
                    }}
                  >
                    <TbX />
                    Deny
                  </div>
                </div>
              )}

              {props.isHome &&
                houseInfo.transferOwnership?.toLowerCase() ===
                  user?.email.toLowerCase() &&
                !houseInfo.helperCreator && (
                  <div
                    style={{
                      padding: "20px 50px",
                    }}
                  >
                    <div id="admin-approve" className="houseControls">
                      <div
                        className="admin-approvebutton"
                        id="accept"
                        onClick={() => {
                          update(
                            ref(database, `${databaseKey}/${houseInfo.key}`),
                            {
                              helperEmail: houseInfo.creatorEmail,
                              helperCreator: houseInfo.creator,
                              creator: user.uid,
                              creatorEmail: user.email,
                              houseApproved: false,
                            }
                          ).then(() => {
                            setShowAcceptPop(true);
                            //navigate(0, { replace: true })
                          });
                        }}
                      >
                        <TbCheck />
                        Accept
                      </div>
                    </div>
                    <p>
                      By accepting this house, I agree that I am in charge of
                      maintaining this house on R-silience and that I am going
                      to do a final proofread before submitting it for inclusion
                      in R-silience.
                    </p>
                  </div>
                )}

              <div className="paddingPoints">
                <BasicPoints title={"Summary"} basicPoints={basicPoints} />

                <Map
                  printMap={houseInfo.address.street !== "0"}
                  address={
                    props.isAdmin
                      ? `${houseInfo.address.street} ${houseInfo.address.city}, ${houseInfo.address.state} ${houseInfo.address.zipCode}`
                      : houseInfo.address.city +
                        ", " +
                        houseInfo.address.state +
                        ", " +
                        houseInfo.address.zipCode
                  }
                  distance={
                    props.distanceDisplay
                      ? `${props.distanceDisplay} Miles Away`
                      : ""
                  }
                  latitude={houseInfo.address.location.latitude}
                  longitude={houseInfo.address.location.longitude}
                />

                <InfoPoints infoPoints={infoPoints} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LocationScreen;
