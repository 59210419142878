import './infoPoints.css'

const InfoPoints = (props) => {

    return (
        <div>
            {props.infoPoints.map((item, index) => {

                // if no item, changed for NARR and certs
                if (!item?.identify && !item?.label) {
                    return null;
                }

                if (item.identify === "title") {
                    return (
                        <div key={index} className={props.navMenu ? "infoPointsTitle navMenuInfoPointsTitle" : "infoPointsTitle"}>{item.label}</div>
                    )
                }

                else if (item.identify === "points") {
                    return (
                        item.content.map((item2, index) => {
                            
                            // if no item2, changed for NARR and certs
                            if (!item2) {
                                return null;
                            }
                            
                            if ((item2.label && !item.recoverySupport) || (item.recoverySupport && item2.value)) {
                                return (
                                    <div
                                        onClick={item2.onClick}
                                        key={index} className={props.navMenu ? "infoPointsContainer navItemInfoPointsContainer" : "infoPointsContainer"}>
                                        {item.onlyOneIcon ? item2.value ? item.iconGreen : item.iconRed : item2.icon}
                                        <div>{item2.label}</div>
                                    </div>
                                )
                            }
                            else {
                                return null
                            }
                        })
                    )
                }

                else {
                    return (
                        <div key={index} className="InfoPointsPara">{item.label}</div>
                    )
                }

            })}
        </div>
    )
}
export default InfoPoints;