import getDistance from '../../../functions/getDistance';
import './HouseItem.css';
import { useState, useContext } from 'react';
import { BsBookmark, BsBookmarkCheckFill } from "react-icons/bs";
import { BsCircleFill } from "react-icons/bs";
import { TbBed, TbPhone, TbReportMoney, TbMapPin, TbEdit, TbMail, TbBellShare, TbHome } from 'react-icons/tb';
import DropdownFRState from '../../formComponents/dropdown/DropdownFRState';
import { update, ref } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import { FirebaseAppContext } from '../../../contexts/firebase';
import AlertPop from '../../pops/alertPop/AlertPop';
import PopBackground from '../../pops/PopBackground';

export default function HouseItem(props) {

    const [bookmarkedHouses,] = useState(props.bookmarkedHouses);
    const [, setBookmarked] = useState(props.bookmarkedHouses?.indexOf(props.item.key) === -1 ? false : true);

    const { database, databaseKey } = useContext(FirebaseAppContext);
    const navigate = useNavigate();
    const [houseApprovePop, setHouseApprovePop] = useState(false);

    const clickFunctionBook = function () {
        if (bookmarkedHouses.indexOf(props.item.key) === -1) {
            bookmarkedHouses.push(props.item.key)
        }
        else {
            let index = bookmarkedHouses.indexOf(props.item.key);
            bookmarkedHouses.splice(index, 1);
        }
        setBookmarked(prev => !prev)
        props.setBookmarkedHouses(bookmarkedHouses)
    }

    let distance;

    if (!props.fromEditHouse && props.useLocation !== null) {
        distance = getDistance(props.useLocation, props.item.address.location);
    }

    // supports new version of image storage minus alt text alongside legacy version
    const image = typeof props.item?.image.image1 == "object" ? props.item?.image?.image1.url : props.item?.image.imageUrl1;

    return <div className='house-item' style={{ cursor: props.fromEditHouse && !props.isAdmin ? "auto" : "pointer" }}>

        <PopBackground
            visible={houseApprovePop}
            component={<AlertPop
                title="The house has been sent to R-silience Staff"
                subtitle="It will be reviewed within 24 hours."
                type="navgateAwayOptions"
                onClickLeft={() => navigate(0, { replace: true })}
                leftTitle="Back to Home"
            />}
            centered={true}
        />

        <div id="house-item-contain">

            {
                (props.showBookmark && (props.fromEditHouse === false || !props.isAdmin)) && <>
                    <div id="circleAndBookContain" onClick={() => { clickFunctionBook(); }}>
                        <BsCircleFill id="circleIconHouse" />
                        {props.bookmarkedHouses?.indexOf(props.item.key) !== -1 ?
                            <BsBookmarkCheckFill className='bookmarkIconHouse' /> : <BsBookmark strokeWidth={.35} className='bookmarkIconHouse' />}
                    </div>
                </>
            }

            <div onClick={() => {
                if (props.fromEditHouse) {
                    //props.onClickEditHouse()
                    if (props.isAdmin) {
                        props.setHouseInfo(props.item)
                    }
                }
                else {
                    props.setDistanceDisplay(distance)
                    props.setSelectedHouse(true);
                    props.setHouseInfo(props.item);
                }
            }}>
                <div className="houseItemImage" style={{ backgroundImage: `url(${image})` }} alt={props.title}>
                    {
                        (props.fromEditHouse && !props.isAdmin) &&
                        <div className='houseItemImage editHouseMenu'>
                            <div className='editHouseIconContainer'>

                                <div className='editHouseIcon'>
                                    <TbBed />
                                    Update Availability

                                    <DropdownFRState
                                        optionValues={[
                                            { placeholder: "Are there beds available?" },
                                            { label: "Yes", value: true },
                                            { label: "No", value: false }
                                        ]}
                                        label={"Are there available beds?"}
                                        value={props.item.availableBeds}
                                        onChange={props.onClickUpdateAvailability}
                                        margin={"10px 0px 20px"}
                                    />
                                </div>

                                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: 10 }}>
                                    <div className='editHouseButton' role='button'
                                        onClick={() => props.onClickEditHouse()}
                                    >
                                        <TbEdit />
                                        Edit House
                                    </div>

                                    <div className='editHouseButton' role='button'
                                        onClick={() => {
                                            props.setHouseInfo(props.item);
                                        }}
                                    >
                                        <TbHome />
                                        View House Page
                                    </div>
                                </div>

                                {
                                    (props.item.helperCreator && !props.item.houseApproved) &&
                                    <div className='sendAdminButton editHouseButton' role='button'
                                        onClick={() => {
                                            update(ref(database, `${databaseKey}/${props.item.key}`), {
                                                pending: true,
                                                houseApproved: true
                                            })
                                                .then(() => {
                                                    setHouseApprovePop(true);
                                                })
                                                .catch((error) => console.log(error.message))
                                        }}
                                    >
                                        <TbBellShare />
                                        Send for R-silience Approval
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>

                <div className='house-info'>
                    <div className='houseItemTitle'>{props.title}</div>
                    {props.isAdmin && <>
                        <div className='house-line'>
                            <TbMail className='houseItemIcons' />
                            <p>
                                {props.item.creatorEmail}
                            </p>
                        </div>

                        <div className='house-line'>
                            <TbMapPin className='houseItemIcons' />
                            <p>
                                {props.item.address.street} {props.item.address.city}, {props.item.address.state} {props.item.address.zipCode}
                            </p>
                        </div>
                    </>}
                    {props.fromEditHouse || !distance ?
                        null :
                        <div className='house-line'>

                            <TbMapPin className='houseItemIcons' />
                            <p>{props.houseCity}, {props.item.address.state} {props.zipCode} {distance ? ` – ${distance} Miles Away` : ''}</p>

                        </div>}

                    <div className='house-line'>
                        <TbReportMoney className='houseItemIcons' />
                        <p>${props.cost} Per Week – {props.intakeFee <= 0 ? "No Intake Fee" : "$" + props.intakeFee + " Intake Fee"}</p>
                    </div>

                    <div className='house-line'>
                        {
                            props.availableBeds ? <TbBed className='houseItemIcons' /> : <TbPhone className='houseItemIcons' />
                        }
                        <p>{props.availableBeds ? "Beds Available" : "Call for Availability"} - {formatPhone(props.item.phoneNumber)}</p>
                    </div>

                </div>

            </div>

        </div>

    </div>
}

const formatPhone = (string) => {
    return `(${string.substring(0, 3)}) ${string.substring(3, 6)}-${string.substring(6)}`
}