import './map.css'
import { TbCar, TbMapPin } from "react-icons/tb";
import BasicPoints from '../basicPoints/BasicPoints';

const Map = (props) => {

    let locationPoints = [
        {
            label: "Area",
            value: props.address,
            icon: <TbMapPin className="locationIcons" />
        },
        {
            label: "Distance",
            value: props.distance,
            icon: <TbCar className="locationIcons" />
        } 
    ]

    return (
        <div>
            <BasicPoints title={"Location"} map={true} basicPoints={props.distance ? locationPoints : [locationPoints[0]]} />
            {props.printMap ?
                <iframe 
                    className="mapEmbed"
                    title = "generalAreaMap"
                    src={`https://www.google.com/maps/embed/v1/view?zoom=17&center=${props.latitude},${props.longitude}&key=AIzaSyCYpUHW0su4ISvvtIIFqvLQuvRSiyfCbVE`}>
                </iframe>
                :
                null
            }
        </div>
    )
}

export default Map;