import './textareaFR.css'
import { useRef, useState } from "react";

const TextareaFR = (props) => {
    const [focusedTextArea, setFocusedTextArea] = useState(false);
    const [charaCount, setCharaCount] = useState(props?.startValue?.length || "0");
    const ref = useRef();

    return (
        <>
            <div className='charaCount'>{charaCount}/600 Characters</div>
            <div className='containerWholeTextArea' style={{ margin: props.margin || null }}>
                <textarea
                    defaultValue={props.startValue || ""}
                    ref={ref}
                    className="textareaInput"
                    maxLength={600}
                    rows="5"
                    style={{ borderColor: props.error && !ref.current?.value 
                        ? focusedTextArea ? "#2a8094" : "red" : null, width: props.width || "100%" }}
                    placeholder="Title"
                    onFocus={() => { setFocusedTextArea(true) }}
                    onBlur={() => { setFocusedTextArea(false) }}
                    onChange={() => { setCharaCount(ref.current?.value?.length) }}
                    id={props.id}
                />

                <div className='containerTextArea'>
                    <div className='textareaLabel'>
                        {props.label}
                    </div>
                </div>

                {/* controls background color of text */}
                <div className={(ref.current?.value === "" || ref.current?.value === undefined) 
                    && !focusedTextArea ? 'containerTextArea textAreaBackgroundColorAni' 
                    : 'containerTextArea textAreaBackgroundColorAni textAreaBackgroundColorAniGo'}
                >
                    {props.label}
                </div>


            </div>
        </>
    )
}
export default TextareaFR;