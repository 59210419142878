import React, { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import './Form.css';

import locationApiSearch from '../../functions/locationApiSearch';

import { ref as databaseRef, serverTimestamp, set } from 'firebase/database';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';

import { v4 as uuidv4 } from 'uuid';

import DropdownFR from '../../components/formComponents/dropdown/DropdownFR';
import ImagePick from '../../components/formComponents/ImagePick/ImagePick';
import InputFR from '../../components/formComponents/inputText/InputFR';
import TextareaFR from '../../components/formComponents/textArea/TextareaFR';
import Survey from '../../components/pops/survey/Survey';

import { Oval as LoadIcon } from 'react-loading-icons';
import AlertPop from '../../components/pops/alertPop/AlertPop';
import FormHeader from '../../components/formComponents/formHeader/FormHeader';
import RadioNARR from '../../components/formComponents/radioNARR/RadioNARR';
import { FirebaseAppContext } from '../../contexts/firebase';
import PopBackground from '../../components/pops/PopBackground';

import { states } from '../../utils/states';

export default function Form() {

    const marginSize = "35px 0px";
    const navigate = useNavigate();
    const location = useLocation();
    const editHouse = location?.state?.house;

    let stateVar = {
        state: {
            bookmarkedHouses: location.state?.bookmarkedHouses
        }
    }

    const { user, database, storage, databaseKey, helper, surveyCompleted, surveyDateDismissed } = useContext(FirebaseAppContext);
    const [surveyVisible, setSurveyVisible] = useState(false);

    const options = [
        { label: "Yes", value: true },
        { label: "No", value: false }
    ];

    const preferNotToAnswer = { label: "Prefer not to answer", value: "0" };

    const [loading, setLoading] = useState(false);

    const [success, setSuccess] = useState(false);

    const [confirmHouseDeleted, setConfirmHouseDeleted] = useState(false);

    const [confirmDeleteAction, setConfirmDeleteAction] = useState(false);
    
    const [cancelConfirm, setCancelConfirm] = useState(false);

    const [formItems, setFormItems] = useState(new Map(
        [
            ["desc1", {
                component: "subtext",
                label: "All fields are required to publish a house. Once submitted, "
                    + "the information will be accessible to all users of the app."
            }],
            ["helperHeader", {
                component: "header",
                label: "Helper Account"
            }],
            ["helperDesc", {
                component: "subtext",
                label: "Enter the email of the house manager you are creating a house for."
            }],
            ["helperTransferOwnership", {
                component: "input",
                autocomplete: "email",
                label: "House Manager's Email",
                value: null,
                error: false
            }],
            ["header1", {
                component: "header",
                label: "Housing Overview"
            }],
            ["desc2", {
                component: "subtext",
                label: "The housing overview will be displayed in full to the app users."
            }],
            ["overview", {
                value: null,
                error: false,
                label: "Housing Overview",
                component: "textArea"
            }],
            ["header2", {
                component: "header",
                label: "Basic Housing Information"
            }],
            ["desc3", {
                component: "subtext",
                label: "The house number and street as well as a negative LGBTQ+ affirming status will not "
                    + "be displayed to the app users. All other information will be displayed."
            }],
            ["title", {
                value: null,
                error: false,
                label: "House name",
                component: "input"
            }],
            ["desc4", {
                component: "subtext",
                label: "The house number and street will not be displayed to the app users. However, "
                    + "a map of the general vicinity of the house will be shown. If the location is confidential, "
                    + "please enter 0 and the map will not be shown."
            }],
            ["street", {
                value: null,
                error: false,
                component: "input",
                label: "House Number and Street (0 if confidential)",
                autocomplete: "street-address"
            }],
            ["city", {
                value: null,
                error: false,
                component: "input",
                label: "City"
            }],
            ["state", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "State" }, ...states]
            }],
            ["zipCode", {
                value: null,
                error: false,
                component: "input",
                type: "number",
                label: "Zip Code"
            }],
            ["website", {
                value: null,
                error: false,
                component: "input",
                type: "text",
                autocomplete: "url",
                label: "Website e.g. website.com (0 if none)"
            }],
            ["phoneNumber", {
                value: null,
                error: false,
                component: "input",
                type: "tel",
                autocomplete: "tel-national",
                label: "Phone Number e.g. 5558008000"
            }],
            ["weeklyFee", {
                value: null,
                error: false,
                component: "input",
                type: "number",
                label: "Estimated Weekly Fee"
            }],
            ["intakeFee", {
                value: null,
                error: false,
                component: "input",
                type: "number",
                label: "Intake Fee (0 if none)"
            }],
            ["totalBeds", {
                value: null,
                error: false,
                component: "input",
                type: "number",
                label: "Total Number of Beds"
            }],
            ["availableBeds", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Are there currently available beds?" }, ...options]
            }],
            ["gender", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "What gender does this particular house accommodate?" },
                { label: "Male", value: "male" }, { label: "Female", value: "female" },
                { label: "All Genders", value: "both" }]
            }],
            ["desc5", {
                component: "subtext",
                label: "The house's LGBTQ+ affirming status will only be displayed if the house is LGBTQ+ affirming."
            }],
            ["lgbtq", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Is the house LGBTQ+ affirming?" }, ...options, preferNotToAnswer]
            }],
            ["descNARR", {
                component: "subtext",
                label: "According to the standard categories of the National Alliance of Recovery Residences (NARR), "
                    + "which level of support best defines this recovery house?"
            }],
            ["narr", {
                value: null,
                error: false,
                component: "radioNARR"
            }],
            ["header3", {
                component: "header",
                label: "Housing Images"
            }],
            ["desc6", {
                component: "subtext",
                label: `All housing images will be appropriately displayed to the app users.`
            }],
            ["imageUrl1", {
                value: null,
                error: false,
                component: "image",
                image1: true,
                optional: false,
                suggestion: "Exterior"
            }],
            ["imageUrl2", {
                value: null,
                error: false,
                component: "image",
                optional: true,
                suggestion: "Bedroom"
            }],
            ["imageUrl3", {
                value: null,
                error: false,
                component: "image",
                optional: true,
                suggestion: "Kitchen"
            }],
            ["imageUrl4", {
                value: null,
                error: false,
                component: "image",
                optional: true,
                suggestion: "Living / Communal Area"
            }],
            ["imageUrl5", {
                value: null,
                error: false,
                component: "image",
                optional: true
            }],
            ["imageUrl6", {
                value: null,
                error: false,
                component: "image",
                optional: true
            }],
            ["imageUrl7", {
                value: null,
                error: false,
                component: "image",
                optional: true
            }],
            ["imageUrl8", {
                value: null,
                error: false,
                component: "image",
                optional: true
            }],
            ["header4", {
                component: "header",
                label: "Recovery Support Options"
            }],
            ["desc7", {
                component: "subtext",
                label: "Only the recovery support options that are offered will be appropriately displayed to the app users."
            }],
            ["mat", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [
                    { placeholder: "Is medication-assisted treatment accepted?" },
                    { label: "Accepted, all forms", value: 1 },
                    { label: "Some forms accepted, ask for details", value: 2 },
                    { label: "Vivitrol accepted", value: 3 },
                    { label: "Not accepted", value: 0 }
                ]
            }],
            ["step12", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Is an on-site 12-step program available?" }, ...options, preferNotToAnswer]
            }],
            ["houseManagers", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Are there on-site house managers?" }, ...options, preferNotToAnswer]
            }],
            ["mentalHealthServices", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Are on-site professional mental health services available?" }, ...options, preferNotToAnswer]
            }],
            ["faithBased", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Is recovery support faith-based?" }, ...options, preferNotToAnswer]
            }],
            ["employmentAssistance", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Is employment assistance available?" }, ...options, preferNotToAnswer]
            }],
            ["peerGroupMeetings", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Are peer group meetings available?" }, ...options, preferNotToAnswer]
            }],
            ["orhCertified", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "ORH certified?" }, ...options]
            }],
            ["carfAccredited", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "CARF accredited?" }, ...options]
            }],
            ["oxfordHouseCertified", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Oxford House certified?" }, ...options]
            }],
            ["narrCertified", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "NARR certified?" }, ...options]
            }],
            ["descCerts", {
                component: "subtext",
                label: "Is this house certified or accredited by another organization?"
            }],
            ["otherCertOrAcredit", {
                value: null,
                error: false,
                component: "input",
                type: "text",
                label: "Other (0 if none)"
            }],
            ["header5", {
                component: "header",
                label: "Policies"
            }],
            ["desc8", {
                component: "subtext",
                label: "All policies will be appropriately displayed to users."
            }],
            ["tobaccoUsage", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Is tobacco usage allowed?" }, ...options]
            }],
            ["serviceAnimals", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Are service animals allowed?" }, ...options]
            }],
            ["overnightVisitors", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Are overnight visitors allowed?" }, ...options]
            }],
            ["kidFriendly", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Is the house kid friendly?" }, ...options]
            }],
            ["header6", {
                component: "header",
                label: "Accommodations and Finances"
            }],
            ["desc9", {
                component: "subtext",
                label: "All accommodations and finance options will be appropriately displayed to the app users."
            }],
            ["wheelchairAccessible", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Is the housing wheelchair accessible?" }, ...options]
            }],
            ["publicTransport", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Is public transportation within walking distance (.25 miles)?" }, ...options]
            }],
            ["pregnancySupport", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Is pregnancy support provided?" }, ...options]
            }],
            ["flexiblePayment", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Are there flexible payment options?" }, ...options]
            }],
            ["medicaid", {
                value: null,
                error: false,
                component: "dropdown",
                optionValues: [{ placeholder: "Are payment assistance or subsidy options available?" }, ...options]
            }],
        ]
    ));

    const checkEmptyInputs = () => {
        let errorCount = 0;

        // iterate through all valid inputs in the form
        for (let i = 0; formRef.current[i].type !== "submit"; i++) {
            const element = formRef.current[i];

            //console.log(element, element.value);

            // checks all dropdowns, text/image inputs
            if (element.value.length === 0) {
                if (isImage(element.id)) {
                    // check all required images
                    if (!editHouse && !formItems.get(element.id).optional) {
                        errorCount++;
                        formItems.set(element.id, { ...formItems.get(element.id), error: true });
                    }
                } else if (element.id === "helperTransferOwnership" && helper) {
                    errorCount++;
                    formItems.set(element.id, {
                        ...formItems.get(element.id),
                        error: true
                    });
                } else {
                    errorCount++;
                    formItems.set(element.id, { ...formItems.get(element.id), error: true });
                }
            } else {
                if (element.type === "radio" && element.name === "narr") {
                    // need to check narr separately since value.length always > 0
                    let butCount = i;
                    let foundNarr = false;

                    // hardcoded # of narr radio buttons
                    for (; butCount < i + 5 && !foundNarr; butCount++) {
                        const radioElement = formRef.current[butCount];
                        if (Object.entries(radioElement)[5][1].getValue() === "true") {
                            foundNarr = true;
                            formItems.set("narr", {
                                ...formItems.get("narr"),
                                value: Number(radioElement.value),
                                error: false
                            });
                        };
                    }
                    if (!foundNarr) {
                        formItems.set("narr", { ...formItems.get("narr"), value: null, error: true });
                        errorCount++;
                    }
                    // continue loop past narr buttons
                    i += 4;
                } else {
                    // clear any non-NARR errors if any
                    formItems.set(element.id, {
                        ...formItems.get(element.id),
                        // images have separate value since it's a file input
                        value: isImage(element.id) ? element.files[0] : element.value,
                        error: false
                    });
                }
            }
        }

        setFormItems(new Map(formItems));
        return errorCount === 0;
    }

    // Call the Google Geocode API and send the new location up to search results
    async function getNewLocation(text) {
        const newLocation = await locationApiSearch(text, false);
        return !!newLocation ? newLocation : null;
    }

    /**
     * Validates the user's inputted URL (https:// is added prior to function call)
     * @param {String} text URL to validate
     * @returns Reg. expression match of a proper URL
     */
    const isValidUrl = (text) => {
        var UrlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator

        return text === "https://0/" ? true : UrlPattern.test(text);
    }

    /**
     * Checks all input elements to ensure they have correct inputs.
     * Checks for valid website URL, address, and all questions answered.
     */
    async function checkValidInputs() {
        if (checkEmptyInputs()) {
            //console.log("inputs clear");
            const website = mapVal("website");
            if (isValidUrl(`https://${website}/`)) {
                //console.log("website clear")
                if (!locationUnchanged()) {
                    const street = mapVal("street");
                    const city = mapVal("city");
                    const state = mapVal("state");
                    const zip = mapVal("zipCode");

                    const locationText = `${street === "0" ? "" : street} ${city}, ${state} ${zip}`
                    //console.log(locationText);
                    const location = await getNewLocation(locationText);

                    if (location === null || location.partialMatch) {
                        // Invalid location or partial match, exact address needed
                        formItems.set("street", { ...formItems.get("street"), error: true });
                        formItems.set("city", { ...formItems.get("city"), error: true });
                        formItems.set("state", { ...formItems.get("state"), error: true });
                        formItems.set("zipCode", { ...formItems.get("zipCode"), error: true });
                        setFormItems(new Map(formItems));
                        alert(`The address "${`${street} ${city}, ${state} ${zip}`}" could not be found. Please check the address and try again.`);
                    } else {
                        // inputs complete, website valid, and location is real
                        setLoading(true)
                        upload(location);
                    }
                } else {
                    setLoading(true)
                    upload(editHouse.address.location);
                }
            }
            else {
                alert(`The website "${website}" could not be found. Please check the URL and try again.`)
            }
        }
        else {
            alert(`All fields must be completed to submit the form. Please make sure each field is complete and try again.`);
        }
    }

    /**
     * Checks to see if address values on form have not been changed on a
     * house that's currently being edited.
     * @returns if address is not changed
     */
    const locationUnchanged = () => {
        return editHouse && (mapVal("street") === editHouse.address.street
            && mapVal("city") === editHouse.address.city
            && mapVal("zipCode") === editHouse.address.zipCode)
    }

    /**
     * Replaces an image from Firebase with a user inputted image file.
     * @param {String} oldImage a Firebase storage URL
     * @param {File} newImage an image File inputted on the Form
     * @returns Firebase storage URL of new image @see uploadImage
     */
    async function replaceImage(oldImage, newImage) {
        try {
            if (oldImage) {
                const newUrl = await uploadImage(newImage);
                deleteImage(oldImage);
                return newUrl;
            } else {
                //console.log("no image");
                return null;
            };
        } catch (error) {
            //console.log(error);
        }
    }

    /**
     * Uploads a new image to Firebase Storage
     * @param {File} uri the file inputted from the form 
     * @returns Firebase storage URL of uploaded image
     */
    async function uploadImage(uri) {
        if (uri) {
            //console.log(uri);
            const fileRef = ref(storage, `${makeStringUrlFriendly(mapVal("title"))}-${uuidv4()}`);
            const metadata = {
                contentType: uri.type
            }

            // eslint-disable-next-line
            const uploadTask = await uploadBytesResumable(fileRef, uri, metadata);
            const url = await getDownloadURL(fileRef);
            //console.log("uBR complete:", url);
            return url;
        } else {
            //console.log("no image");
            return null;
        }
    }

    /**
     * Deleted the image at the provided URL
     * @param {String} url Firebase URL for image 
     */
    const deleteImage = (url) => {
        if (url) {
            const imageRef = ref(storage, url);

            deleteObject(imageRef)
                .then(() => {
                    // image deleted
                    console.log("image deleted");
                })
                .catch((error) => {
                    console.log(error.code);
                });
        } else {
            //console.log("no image");
            return;
        }
    }

    /**
     * A handler function that calls parent upload/replace functions.
     * @param {String} oldImage URL of image already uploaded to Firebase
     * @param {File} newImage new File uploaded to form
     * @returns a URL of an image referenced to Firebase
     */
    const imageHandler = async (oldImage, newImage) => {
        //console.log(newImage);
        if (editHouse) {
            if (oldImage && newImage) {
                return await replaceImage(oldImage, newImage);
            } else if (oldImage && !newImage) {
                return oldImage;
            }
        }
        return await uploadImage(newImage);
    }

    /**
     * Deletes the recovery house being edited.
     */
    const deleteHouse = () => {
        deleteImage(editHouse?.image?.imageUrl1);
        deleteImage(editHouse?.image?.imageUrl2);
        deleteImage(editHouse?.image?.imageUrl3);
        deleteImage(editHouse?.image?.imageUrl4);
        deleteImage(editHouse?.image?.imageUrl5);
        deleteImage(editHouse?.image?.imageUrl6);
        deleteImage(editHouse?.image?.imageUrl7);
        deleteImage(editHouse?.image?.imageUrl8);

        deleteImage(editHouse?.image?.image1?.url);
        deleteImage(editHouse?.image?.image2?.url);
        deleteImage(editHouse?.image?.image3?.url);
        deleteImage(editHouse?.image?.image4?.url);
        deleteImage(editHouse?.image?.image5?.url);
        deleteImage(editHouse?.image?.image6?.url);
        deleteImage(editHouse?.image?.image7?.url);
        deleteImage(editHouse?.image?.image8?.url);

        set(databaseRef(database, `${databaseKey}/${editHouse.key}`), {})
            .then(() => {
                // house deleted
                console.log("house deleted");
                setConfirmHouseDeleted(true);
            })
            .catch((error) => {
                console.log(error.code);
            });
    }

    /**
      * Checks if current key identifies with an image,
      * assuming all image keys in RTBD start with "imageURL".
      * @param {String} key the key of the form component
      * @returns if key associates with an image component
    */
    const isImage = (key) => {
        return key.slice(0, 8) === "imageUrl";
    }

    /**
     * Uploads the house to Firebase Realtime Database
     * @param {*} location Object containing house's coordinates based on address
     */
    async function upload(location) {
        console.log("starting upload");

        // use new image storage format
        const images = { ...editHouse?.image };
        for (let i = 1; i <= 8; i++) {
            const image = mapVal(`imageUrl${i}`);
            if (image) {
                images[`image${i}`] = {
                    url: await imageHandler(editHouse?.image[`image${i}`], image),
                    alt: '',
                };
            }
        }

        set(databaseRef(database, `${databaseKey}/${editHouse ? editHouse.key : `${makeStringUrlFriendly(mapVal("title"))}-${uuidv4()}`}`), {
            ...(editHouse ?? {}),
            address: {
                city: mapVal("city"),
                location: editHouse && locationUnchanged() ?
                    editHouse.address.location : {
                        longitude: location.longitude,
                        latitude: location.latitude
                    },
                state: mapVal("state"),
                street: mapVal("street"),
                zipCode: mapVal("zipCode")
            },
            image: images,
            availableBeds: mapVal("availableBeds") === "true" ? true : false,
            gender: {
                male: mapVal("gender") === "male" || mapVal("gender") === "both",
                female: mapVal("gender") === "female" || mapVal("gender") === "both",
                lgbtq: mapVal("lgbtq") !== "0" ? mapVal("lgbtq") === "true" : 0
            },
            website: `https://${mapVal("website")}/`,
            overview: mapVal('overview'),
            phoneNumber: mapVal("phoneNumber"),
            weeklyFee: Number(mapVal("weeklyFee")),
            mat: Number(mapVal("mat")),
            totalBeds: Number(mapVal("totalBeds")),
            intakeFee: Number(mapVal("intakeFee")),
            searchFilters: {
                tobaccoUsage: mapVal('tobaccoUsage') === 'true',
                publicTransport: mapVal('publicTransport') === 'true',
                faithBased: mapVal("faithBased") !== "0" ? mapVal("faithBased") === 'true' : 0,
                houseManagers: mapVal("houseManagers") !== "0" ? mapVal("houseManagers") === 'true' : 0,
                mentalHealthServices: mapVal("mentalHealthServices") !== '0' ? mapVal('mentalHealthServices') === 'true' : 0,
                peerGroupMeetings: mapVal("peerGroupMeetings") !== 0 ? mapVal("peerGroupMeetings") === 'true' : 0,
                step12: mapVal("step12") !== 0 ? mapVal("step12") === 'true' : 0,
                employmentAssistance: mapVal("employmentAssistance") !== '0' ? mapVal("employmentAssistance") === 'true' : 0,
                pregnancySupport: mapVal("pregnancySupport") === 'true',
                overnightVisitors: mapVal("overnightVisitors") === 'true',
                medicaid: mapVal("medicaid") === 'true',
                narr: Number(mapVal("narr")),
                kidFriendly: mapVal("kidFriendly") === 'true',
                flexiblePayment: mapVal("flexiblePayment") === 'true',
                serviceAnimals: mapVal("serviceAnimals") === 'true',
                wheelchairAccessible: mapVal("wheelchairAccessible") === 'true',
                orhCertified: mapVal("orhCertified") === 'true',
                carfAccredited: mapVal("carfAccredited") === 'true',
                oxfordHouseCertified: mapVal("oxfordHouseCertified") === 'true',
                narrCertified: mapVal("narrCertified") === 'true',
                otherCertOrAcredit: mapVal("otherCertOrAcredit"),
            },
            title: mapVal("title"),
            creator: user.uid,
            creatorEmail: user.email,
            // use current pending value if editing a house or false if no value
            pending: editHouse ? editHouse?.pending || false : true,
            transferOwnership: mapVal("helperTransferOwnership"),
            helperCreator: editHouse?.helperCreator || null,
            helperEmail: editHouse?.helperEmail || null,
            publishedAt: editHouse?.publishedAt || serverTimestamp(),
            editedAt: serverTimestamp()
        })
            .then(() => {
                //upload successful
                setSuccess(true);
                formItems.clear();
            })
            .catch((error) => {
                setLoading(false);
                alert(`Something went wrong during the upload. Please check your responses and try again.`)
                console.log(error.code);
            })
    }

    // Helper function to pull value from formItems Map
    const mapVal = (string) => {
        return formItems.get(string).value;
    }

    /**
     * Takes a string and makes it "URL friendly".
     * ie. "This string isn't friendly" returns "this-string-isnt-friendly"
     * @param {*} string string to make URL friendly
     * @returns URL friendly string
     */
    const makeStringUrlFriendly = (string) => {
        return string.match(/[A-z\s]/g).join("").replaceAll(" ", "-").toLowerCase();
    }

    // ref attached to form, see checkEmptyInputs
    const formRef = useRef();

    const handleNav = () => {
        const currentTime = new Date().getTime();
        const timeSinceLastDismiss = surveyDateDismissed ? new Date(surveyDateDismissed)?.getTime() : null;

        // show survey if not completed and 
        // if never dismissed, or time since last dismiss has been a week
        if (!surveyCompleted && (!timeSinceLastDismiss || currentTime - timeSinceLastDismiss >= 604800000)) {
            setSurveyVisible(true);
        } else {
            navigate("/", stateVar);
        }
    }

    return (
        <>
            {loading || confirmHouseDeleted ?
                null :
                <FormHeader
                    onClickCancel={() => { setCancelConfirm(true); }}
                    houseTitle={editHouse?.title}
                    onClickSave={() => { checkValidInputs(); }}
                />
            }


            <PopBackground
                visible={cancelConfirm}
                component={<AlertPop
                    title={`If you leave, any changes you made will be discarded.`}
                    subtitle="What would you like to do?"
                    type="navgateAwayOptions"
                    onClickLeft={() => { setCancelConfirm(false) }}
                    leftTitle="Stay Here"
                    rightTitle="Discard Changes"
                    descructive={true}
                    onClickRight={() => {
                        navigate("/")
                    }}
                />}
                centered={true}
            />

            <div className='backgroundForm' style={{ backgroundColor: "#2a8094", zIndex: -5 }} />

            <form id="HousingProviderForm"
                className='formEle'
                style={{ display: loading || confirmHouseDeleted ? "none" : null }}
                onSubmit={(e) => {
                    e.preventDefault();
                    checkValidInputs(); // runs the upload at the end too
                }}
                ref={formRef}
            >

                {
                    // render form components
                    Array.from(formItems).map((element) => {
                        const value = element[1];
                        const key = element[0];

                        return !helper && key.includes("helper") ? null : <React.Fragment key={key}>
                            {
                                value.component === "input" ?
                                    <InputFR
                                        margin={marginSize}
                                        isForm={true}
                                        width="100%"
                                        type={value.type}
                                        error={value.error}
                                        inputRef={value.ref}
                                        label={value.label}
                                        autocomplete={value.autocomplete}
                                        startValue={editHouse ?
                                            (key === "city" || key === "street" || key === "zipCode") ?
                                                editHouse.address[key] :
                                                key === "website" ? editHouse.website.slice(8, editHouse.website.length - 1)
                                                    : key === "helperTransferOwnership" ? editHouse.transferOwnership :
                                                        !editHouse[key] && !editHouse.searchFilters[key] ? "0"
                                                            : editHouse[key] || editHouse.searchFilters[key]
                                            : ""
                                        }
                                        id={key}
                                        blur={() => { return }}
                                    />
                                    :
                                    value.component === "textArea" ?
                                        <TextareaFR
                                            margin={marginSize}
                                            label={value.label}
                                            error={value.error}
                                            inputRef={value.ref}
                                            startValue={editHouse?.overview}
                                            id={key}
                                        />
                                        : value.component === "dropdown" ?
                                            <DropdownFR
                                                focusedInput={false}
                                                id={key}
                                                margin={marginSize}
                                                optionValues={value.optionValues}
                                                label={value.label}
                                                inputRef={value.ref}
                                                error={value.error}
                                                startValue={
                                                    editHouse ?
                                                        key === "gender" ?
                                                            (editHouse.gender.male && editHouse.gender.female) ? "both" :
                                                                editHouse.gender.male ? "male" : "female"
                                                            : key === "lgbtq" ?
                                                                `${editHouse.gender.lgbtq}`
                                                                : key === "availableBeds" ?
                                                                    `${editHouse.availableBeds}`
                                                                    : key === "mat" ?
                                                                        `${editHouse.mat}`
                                                                        : key === "state" ? `${editHouse.address.state}`
                                                                            : `${editHouse.searchFilters[key]}`
                                                        : "" // editHouse no value
                                                }
                                            />
                                            : value.component === "image" ?
                                                <ImagePick
                                                    id={key}
                                                    image1={typeof editHouse?.image?.imageUrl1 === 'string' ? editHouse?.image.imageUrl1 : editHouse?.image1?.url}
                                                    inputRef={value.ref}
                                                    error={value.error}
                                                    startImage={typeof editHouse?.image?.[key] === 'string' ? (editHouse?.image?.[key]) : editHouse?.image?.[`image${key.replace('imageUrl', '')}`]?.url}
                                                    optional={value.optional}
                                                    suggestion={value.suggestion}
                                                />
                                                : value.component === "radioNARR" ?
                                                    <RadioNARR
                                                        startValue={editHouse ? editHouse?.searchFilters.narr : false}
                                                        error={value.error}
                                                    />
                                                    : value.component === "header" ?
                                                        <h3
                                                            className='form-header'
                                                            style={{ marginTop: value.label === "Housing Overview" ? "30px" : null }}
                                                        >
                                                            {value.label}
                                                        </h3>
                                                        : <div className='form-subtext'>
                                                            <p>{value.label}</p>
                                                        </div>
                            }
                        </React.Fragment>
                    })
                }

                <h3 className='form-header'>
                    Submit Housing Information
                </h3>
                <div className='form-subtext'>
                    <p>Please ensure that all fields are filled out to the best of your ability.</p>
                </div>
                <input className="submitForm" value={editHouse ? "Save Changes" : "Upload House"} type="submit" />
                {
                    !!editHouse && <input
                        type="button"
                        className="submitForm deleteHouse"
                        onClick={() => { setConfirmDeleteAction(true) }}
                        value="Delete House"
                    />
                }
            </form>

            <PopBackground
                visible={success}
                component={<AlertPop
                    title={`${editHouse ? "The house was successfully edited." :
                        !helper ? "Thanks for publishing your house, it will be reviewed within 24 hours." : "Thanks for publishing this house as a helper."}`}
                    subtitle={`${helper ? "You will be able to edit this house until the housing manager accepts it." : ""}`}
                    type="navgateAwayOptions"
                    onClickLeft={() => {
                        handleNav();
                    }}
                    leftTitle="Back to Home"
                />}
                centered={true}
            />

            <div className="uploadingContainer" style={{ display: loading ? "flex" : "none" }}>
                {!success && <div className='loadscreen'>
                    <LoadIcon strokeWidth={4} className='loadIcon' />
                    Uploading...
                </div>
                }
            </div>

            <PopBackground
                visible={confirmDeleteAction}
                component={<AlertPop
                    title={`Are you sure you want to delete this house?`}
                    subtitle="This action cannot be undone."
                    type="navgateAwayOptions"
                    onClickLeft={() => { setConfirmDeleteAction(false) }}
                    leftTitle="Don't Delete"
                    rightTitle={`Delete this House`}
                    descructive={true}
                    onClickRight={() => { deleteHouse(); }}
                />}
                centered={true}
            />

            <PopBackground
                visible={confirmHouseDeleted}
                component={<AlertPop
                    title={`The house was deleted successfully!`}
                    subtitle="What would you like to do?"
                    type="navgateAwayOptions"
                    onClickLeft={() => handleNav()}
                    leftTitle="Back to Home"
                />}
                centered={true}
            />

            {
                surveyVisible && <Survey
                    controlledBy={surveyVisible}
                    navAfter={() => navigate("/", stateVar)}
                />
            }

        </>
    )
}