import "./Admin.css";
import Header from "../../components/homeHeaderComponent/Header";
import { HousesTab } from "./HousesTab";
// import { CertsTab } from "./CertsTab";
import { SurveysTab } from "./SurveysTab";
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Container,
  Box,
  Button,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

export const Admin = () => {
  const tabs = [
    { name: "Houses", component: <HousesTab /> },
    // { name: "Certs", component: <CertsTab /> },
    { name: "Surveys", component: <SurveysTab /> },
  ];

  const navigate  = useNavigate();

  return (
    <>
      <Header />
      <Box margin={"3rem"}>
        <Container
          maxW={"1400px"}
          borderRadius={"8px"}
          padding={"1rem"}
          boxShadow={"md"}
        >
          <Button variant={"link"} color={"rblue.600"} onClick={() => navigate('/home')}>
            <ArrowBackIcon />
            Home
          </Button>
          <Tabs variant={"line"} colorScheme="rblue" isFitted>
            <TabList>
              {tabs.map((tab) => (
                <Tab key={tab.name} mr={"2rem"}>
                  {tab.name}
                </Tab>
              ))}
            </TabList>
            <TabPanels
              minH={"400px"}
              h={"fit-content"}
              marginTop={"1rem"}
              bgGradient={"linear(to-br, rblue.50, white)"}
              borderRadius={"4px"}
            >
              {tabs.map((tab) => (
                <TabPanel key={tab.component}>{tab.component}</TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Container>
      </Box>
    </>
  );
};
