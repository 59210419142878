import './imageScroll.css';
import { useState, useRef, useEffect } from 'react';
import { BsCircleFill, BsArrowRightShort, BsArrowLeftShort } from "react-icons/bs";
//npm install react-icons --save

const ImageScroll = (props) => {
  const imageScrollContainRef = useRef();
  const imageLength = props?.images ? props.images.length : 0;
  let count = (100 / (imageLength - 1));
  let startPoint;
  let endPoint;
  const [scrollProgress, setScrollProgress] = useState(0);

  const scrollListener = () => {
    if (!imageScrollContainRef.current) { //if the imageScrollContain ref isn't a thing, get out of here
      return;
    }

    const imageContainer = imageScrollContainRef.current;
    const windowScroll = imageContainer.scrollLeft; //distance of the scrollbar from the left of the image container
    const totalWidth = imageContainer.scrollWidth - imageContainer.clientWidth; //total distance the scrollbar can move in the image container 

    if (windowScroll === 0) {
      return setScrollProgress(0);
    }

    if (windowScroll > totalWidth) {
      return setScrollProgress(100);
    }

    setScrollProgress((windowScroll / totalWidth) * 100); //the percentage of the total distance that has been scrolled
  }

  function startAndEndPoints(index) {
    startPoint = (count / 2) + count * (index - 1);
    endPoint = startPoint + count;
  }

  useEffect(() => {
    let observerRefValue = null;
    observerRefValue = imageScrollContainRef.current; //use a local var in usEffect because of this warning:

    /* The ref value 'imageScrollContainRef.current' will likely have changed by 
    the ime this effect cleanup function runs. If this ref points to a node rendered 
    by React, copy 'imageScrollContainRef.current' to a variable inside the effect, 
    and use that variable in the cleanup function  react-hooks/exhaustive-deps */

    imageScrollContainRef.current.addEventListener('scroll', scrollListener); //add scroll listener to imageScrollContain
    //event listeners are added on top of previous event listeners each time the useEffect runs / when there's a rerender
    return () => observerRefValue ? observerRefValue.removeEventListener('scroll', scrollListener) : null;
    //cleanup -- return runs before the useEffect
    //only runs if there is a ref, if there isn't a ref and it tries to run it could crash in specific instances
  });

  return (
    <div style={{ marginBottom: -69, }}>

      <div className="imageScrollContain" ref={imageScrollContainRef} style={{ overflow: "scroll", scrollBehavior: "smooth", }}>
        <div className="subImageScrollContain" style={{ width: imageLength * 100 + "%", }}>

          {props.images && props.images.map((image, imageIndex) => {
            //creating pannel of images -- works for a dynamic number of images now :)
            // partially adding support for new image system (no alt text yet)
            const imageUrl = typeof image == "object" ? image.url : image;
            return (
              <div 
                key={imageIndex} 
                style={{ scrollSnapAlign: "start", backgroundImage: `url(${imageUrl})` }} 
                name={imageIndex} 
                id={imageIndex} 
                className='imagesBack' 
              />
            );
          })}

        </div>
      </div>

      {props.images && props.images.map((image, index) => {
        //rendering in the scroll arrows
        startAndEndPoints(index);

        if (scrollProgress >= startPoint && scrollProgress <= endPoint) {
          return (
            <div key={index} className='imageNavContain' style={{ justifyContent: index === 0 ? "flex-end" : null }}>

              {index !== 0 ?
                <div
                  onClick={() => {
                    imageScrollContainRef.current.children[0].children[index - 1].scrollIntoView(true);
                  }}
                >
                  <BsArrowLeftShort className='arrow' />
                  <BsCircleFill className='arrowColor' />
                </div>
                :
                null
              }

              {index !== imageLength - 1 ?
                <div
                  onClick={() => {
                    imageScrollContainRef.current.children[0].children[index + 1].scrollIntoView(true);
                  }}
                >
                  <BsArrowRightShort className='arrow' />
                  <BsCircleFill className='arrowColor' />
                </div>
                :
                null
              }

            </div>
          );

        }
        return null;
      })
      }

      <div className='dotContainer'>
        <div className="dotWhiteContainer">
          {props.images && props.images.map((image, index) => {
            // combining the two .maps could work but the dotContainer and dotWhiteContainer 
            // classes need to run outside of the .map and can only contain the dot indicators
            // having the arrows in there causes layout issues
            startAndEndPoints(index);

            return (
              <div key={index}>
                <div className="indication"
                  onClick={() => {
                    imageScrollContainRef.current.children[0].children[index].scrollIntoView(true);
                  }}
                >
                  <BsCircleFill className="indicationDot"
                    style={{
                      color: scrollProgress >= startPoint && scrollProgress <= endPoint ? "#2a8094" : null,
                      fontSize: scrollProgress >= startPoint && scrollProgress <= endPoint ? "11px" : null,
                      border: scrollProgress >= startPoint && scrollProgress <= endPoint ? "none" : null,
                    }}
                  />
                </div>
              </div>
            );

          })}
        </div>
      </div>

    </div>
  )
}
export default ImageScroll;