import './basicPoints.css'

const BasicPoints = (props) => {
    return (
        <div>
            <div className="basicPointsTitle">{props.title}</div>
            <div className='basicPoints' style={{ marginTop: props.map ? "15px" : null }}>
                {props.basicPoints.map((item, index) => {
                    return item && <div key={index} className="basicPointContainer">
                        {item?.icon}
                        <div className="textBasicPoint"><b>{item?.label}:</b> {item?.value}</div>
                    </div>
                })
                }
            </div>
        </div>
    )
}
export default BasicPoints;