import './profileContainer.css'
import { IoClose } from "react-icons/io5";
import EditInfo from '../profileSettings/EditInfo';

const ProfileContainer = (props) => {

    return (
        <>
            <div className="profileContainer" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", pointerEvents: "auto" }} onClick={() => { props.setShowItem(false); }} />
            
            <div className="profileContainer">
                <div id="subProfileContainer">
                    <IoClose id="profileCloseIcon"
                        onClick={() => {
                            props.setShowItem(false);
                        }}
                    />
                    <EditInfo change={props.change} setShowItem={props.setShowItem} />
                </div>

            </div>
        </>


    )
}
export default ProfileContainer;