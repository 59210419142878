import './authContainer.css'
import { IoClose } from "react-icons/io5";
import { useState } from 'react';
import SignIn from '../../../pages/signIn/SignIn';
import SignUp from '../../../pages/signUp/SignUp';
import ResetPassword from '../../../pages/resetPassword/ResetPassword';

const AuthContainer = (props) => {
    const [onSignIn, setOnSignIn] = useState(props.onSignIn || false);
    const [showForgotPassword, setShowForgotPassword] = useState(false);

    function decorationText() {
        return (
            <>
                <img src={require("../../../imgs/RSLogo.png")} style={{ width: "50%", height: "auto" }} alt="R-silience Logo" />
                <p className="tagLine">
                    A successful recovery journey should not be left up to chance.
                </p>
            </>
        )
    }

    return (
        <>
            <div id="subAuthContainer">

                <IoClose id="authCloseIcon"
                    onClick={() => {
                        props.setShowAuthPop(false);
                    }}
                />

                <div id="signUpContinerAuth">
                    <div id="decorationAuthResp">
                        {decorationText()}
                    </div>

                    <div id="signUpPadding">
                        {
                            showForgotPassword ?
                                <ResetPassword />
                                :
                                onSignIn ?
                                    <SignIn setSignedIn={props.setSignedIn} setShowAuthPop={props.setShowAuthPop} setShowForgotPassword={setShowForgotPassword} />
                                    :
                                    <SignUp setSignedIn={props.setSignedIn} setShowAuthPop={props.setShowAuthPop} />
                        }
                    </div>
                    <div className="navAway">
                        <div className="navAwayText"
                            onClick={() => {
                                if (showForgotPassword) {
                                    setShowForgotPassword(false);
                                }
                                else {
                                    setOnSignIn(prev => !prev);
                                }
                            }}
                        >
                            {showForgotPassword ? "Back to sign in." : onSignIn ? "Need an account? Sign up." : "Already have an account? Sign in."}
                        </div>
                    </div>
                </div>
                <div id="decorationAuth">
                    {decorationText()}
                </div>
            </div>
        </>
    )
}
export default AuthContainer;