import "./AuthHome.css";
import "../HomeNoAuth/home.css";
import "../search/search.css";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { FirebaseAppContext } from "../../contexts/firebase";
import { onValue, ref, update } from "firebase/database";
import Header from "../../components/homeHeaderComponent/Header";
import LocationSearch from "../../components/locationSearch/LocationSearch";
import { useLoaderData, useLocation } from "react-router-dom";
import Disclaimer from "../../components/pops/disclaimers/Disclaimer";
import { TbInfoCircle } from "react-icons/tb";
import HouseItem from "../../components/searchComponents/houseItem/HouseItem"
import LocationScreen from "../locationScreen/LocationScreen";
import AlertPop from "../../components/pops/alertPop/AlertPop";
import PopBackground from "../../components/pops/PopBackground";
import Button from "../../components/Button/Button";

export default function AuthHome() {
    const navigate = useNavigate();

    const { user, database, databaseKey, admin, helper } = useContext(FirebaseAppContext);
    const [houses, setHouses] = useState(null);
    const [pendingHouses, setPendingHouses] = useState(null);

    const [displayHouse, setDisplayHouse] = useState(null);

    const location = useLocation();

    const data = useLoaderData();

    const currentLocation = data?.location;
    const currentLocationText = data?.text;

    const [showDisclaimer, setShowDisclaimer] = useState(localStorage.getItem("providerDisclaimer") !== "true");
    const [showInfoPop, setShowInfoPop] = useState(false);

    // pulls current user's houses and any pending houses they should be able to see
    useEffect(() => {
        onValue(ref(database, databaseKey), (snapshot) => {
            const yourHouses = [];
            const pending = [];
            snapshot.forEach((childSnapshot) => {
                const childData = childSnapshot.val();
                if (childData.creator === user?.uid) {
                    // house manager created or approved
                    yourHouses.push({ ...childData, key: childSnapshot.key });
                }

                // reusing the same HTML pending section for both admins and houses
                // if admin and pending OR house manager has house that is NOT accepted
                if (childData.pending) {
                    //  if admin and (no transfer/house made) OR helper created, house accepted, and house approved.
                    if (admin && (!childData.transferOwnership || (childData.helperCreator && childData.houseApproved))) {
                        pending.push({ ...childData, key: childSnapshot.key });
                    } else if (childData.transferOwnership?.toLowerCase() === user.email?.toLowerCase() && !childData.helperCreator) {
                        // helper made, but not house accepted
                        pending.push({ ...childData, key: childSnapshot.key });
                    }
                }
            });

            // second render for some reason that doesn't wait for onValue to finish?
            if (yourHouses.length > 0) {
                setHouses([...yourHouses]);
            }

            if (pending.length > 0) {
                setPendingHouses([...pending]);
            }

        }, { onlyOnce: true });
    }, [user, admin, database, databaseKey, helper]);

    return <>
        <div className="homeContainer">

            <Header />

            <PopBackground
                visible={showInfoPop}
                component={<AlertPop
                    title="Helper Houses"
                    subtitle={`Helper houses are created by you and assigned to a Recovery House Manager via email address. 
                        You will be able to edit the house until the House Manager approves the information of the house you
                        created is correct.`}
                    type="navgateAwayOptions"
                    onClickLeft={() => {
                        setShowInfoPop(false);
                    }}
                    leftTitle="Close"
                />}
                hidePop={setShowInfoPop}
                navOut={true}
                centered={true}
            />

            <div className="page-content">

                <div className="searchContainer" style={{ maxWidth: 375, padding: 0, margin: "0px 0px 25px" }}>
                    <p style={{ color: "white", fontWeight: 100, fontSize: 16, marginBottom: 5 }}>
                        Search for recovery houses on R-silience
                    </p>
                    <LocationSearch
                        homeScreen={true}  // change to true if styling changes
                        currentLocationText={currentLocationText}
                        currentLocation={currentLocation}
                        onNewSearch={(e) => navigate("/search",
                            {
                                state: {
                                    bookmarkedHouses: location?.state?.bookmarkedHouses || [],
                                    currentLocationText: currentLocationText,
                                    currentLocation: currentLocation,
                                    useLocation: e.location,
                                    locationText: e.text
                                }
                            }
                        )}
                        onUseCurrent={(values) => navigate("/search",
                            {
                                state: {
                                    bookmarkedHouses: location?.state?.bookmarkedHouses || [],
                                    currentLocationText: values.text,
                                    currentLocation: values.location,
                                    useLocation: values.location,
                                    locationText: values.text
                                }
                            }
                        )}
                    />
                </div>

                <div className="housesContainer"
                    style={{
                        margin: "0px 0px",
                        paddingBottom: 40,
                        paddingLeft: 10,
                        width: "100%",
                        borderTop: "5px solid #EDD36A",
                        borderBottom: "5px solid #EDD36A",
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "white",
                    }}
                >

                    <div className="yourHousesHeader" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <h2 style={{ fontWeight: 500, fontFamily: "Georgia", fontSize: 36, color: "#2a8094", marginRight: 10, marginBottom: 30 }}>
                            Your {helper ? "Helper" : ""} Houses
                        </h2>
                        {helper && <div role="button" onClick={() => setShowInfoPop(true)}
                            title="Helper House Info (click)"
                        >
                            <TbInfoCircle
                                style={{ color: "gray", height: 24, width: 24, strokeWidth: 1.5, margin: "10px 20px 0px -5px", cursor: "poiner" }}
                            />
                        </div>}
                        <div className="publishButton">
                            <Button
                                text="Publish New House"
                                onClick={() => navigate("/form")}
                                size="small"
                            />
                        </div>
                    </div>

                    <div className="yourHouses"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: 20
                        }}
                    >
                        {
                            houses && houses.map((item, index) => {
                                return <div className="editHouseContainer" key={index}
                                    style={{
                                        backgroundColor: "white",
                                        width: 350,
                                        margin: 10,
                                        borderRadius: 4
                                    }}
                                >
                                    <HouseItem
                                        fromEditHouse={true}
                                        setDistanceDisplay={false}
                                        isAdmin={false}
                                        showBookmark={false}
                                        onClickEditHouse={() => {
                                            navigate("/form", {
                                                state: {
                                                    bookmarkedHouses: location?.state?.bookmarkedHouses,
                                                    house: item
                                                }
                                            })
                                        }}
                                        onClickUpdateAvailability={(val) => {
                                            const boolVal = val === "true" ? true : false;
                                            update(ref(database, `${databaseKey}/${item.key}`), { availableBeds: boolVal })
                                                .then(() => {
                                                    navigate(0, { replace: true, state: location?.state })
                                                });
                                        }}
                                        item={item}
                                        title={item.title}
                                        image1={item.image.imageUrl1}
                                        cost={item.weeklyFee}
                                        intakeFee={item.intakeFee}
                                        availableBeds={item.availableBeds}
                                        setHouseInfo={setDisplayHouse}
                                    />

                                </div>
                            })
                        }
                    </div>

                </div>

                {
                    (admin || pendingHouses?.length > 0) && <div className="housesContainer"
                        style={{
                            margin: "30px 0px",
                            paddingBottom: 40,
                            paddingLeft: 10,
                            width: "100%",
                            borderTop: "5px solid #EDD36A",
                            borderBottom: "5px solid #EDD36A",
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "white",
                        }}
                    >

                        <h2 style={{ fontWeight: 500, fontFamily: "Georgia", fontSize: 36, color: "#2a8094", marginRight: 10, marginBottom: 30 }}>
                            Pending Houses {!helper && !admin ? "Made by R-silience Staff for You" : ""}
                        </h2>

                        <div className="yourHouses"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                gap: 20,
                            }}>
                            {
                                pendingHouses?.length > 0 ? pendingHouses.map((item, index) => {
                                    return <div className="editHouseContainer" key={index}
                                        style={{
                                            backgroundColor: "white",
                                            width: 350,
                                            margin: 10,
                                            borderRadius: 4
                                        }}
                                    >
                                        <HouseItem
                                            fromEditHouse={true}
                                            isAdmin={true}
                                            setDistanceDisplay={false}
                                            item={item}
                                            title={item.title}
                                            image1={item.image.imageUrl1}
                                            cost={item.weeklyFee}
                                            intakeFee={item.intakeFee}
                                            availableBeds={item.availableBeds}
                                            setHouseInfo={setDisplayHouse}
                                        />

                                    </div>
                                }) : <p>No pending houses</p>
                            }
                        </div>

                    </div>
                }

                <div className={displayHouse ? "backgroundOverlay houseFadeIn" : "backgroundOverlay houseFadeOut"}
                    style={{
                        zIndex: 2,
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        pointerEvents: displayHouse ? "auto" : "none"
                    }}
                    onClick={() => { setDisplayHouse(false) }}
                />


                {
                    displayHouse && <div id="locationScreenContainSearch">
                        <LocationScreen
                            houseInfo={displayHouse}
                            setSelectedHouse={setDisplayHouse}
                            isAdmin={admin}
                            houseApprove={!admin && !helper}
                            isHome={true}
                        />
                    </div>
                }

                {
                    <PopBackground
                        visible={showDisclaimer}
                        component={<Disclaimer
                            type="provider"
                            onAgree={() => {
                                localStorage.setItem("providerDisclaimer", "true");
                                setShowDisclaimer(false);
                            }}
                        />}
                        centered={true}
                    />
                }
            </div>
        </div>
    </>
}