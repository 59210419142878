import {
  Button,
  Input,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import { FirebaseAppContext } from "../../contexts/firebase";
import { ref, update } from "firebase/database";

export const RecentContactModal = ({
  isOpen,
  onClose,
  name,
  contactDate = new Date().toISOString().split("T")[0],
  contactAdmin = "", // default values in to prevent form control errors
  itemKey,
  onSave
}) => {
  const { database, databaseKey } = useContext(FirebaseAppContext);

  const [date, setDate] = useState(contactDate);
  const [admin, setAdmin] = useState(contactAdmin);

  // for prepopulating the modal fields
  useEffect(() => {
    setDate(contactDate);
    setAdmin(contactAdmin);
  }, [contactDate, contactAdmin]);

  // This attempts two things: 
  // 1. send the updated values to the database and 
  // 2. callback function that updates the state
  const handleSave = async () => {
    try {
      const itemRef = ref(database, `${databaseKey}/${itemKey}`);
      await update(itemRef, { contactDate: date, contactAdmin: admin });
      onSave({ contactDate: date, contactAdmin: admin });
      console.log("Last contact date updated successfully");
    } catch (error) {
      console.error("Error updating last contact date: ", error);
    }
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} colorScheme="rblue">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="date" mb={4}>
            <FormLabel>Date last contacted:</FormLabel>
            <Input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </FormControl>
          <FormControl id="name">
            <FormLabel>Contacted by:</FormLabel>
            <Input value={admin} onChange={(e) => setAdmin(e.target.value)} />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="rblue" mr={3} onClick={handleSave}>
            Save
          </Button>
          <Button variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
