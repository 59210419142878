import roundNum from './roundNum';

export default function getDistance(yourPos, housePos) {
    var haversine = require("haversine-distance");  // npm install --save haversine-distance

    var point1 = { lat: yourPos?.latitude, lng: yourPos?.longitude };
    var point2 = { lat: housePos.latitude, lng: housePos.longitude };

    //var haversine_m = haversine(point1, point2);

    return yourPos ? roundNum(haversine(point1, point2) / 1609.344) : null;    // converts meters to miles
}