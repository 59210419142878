import './signUp.css'
import React, { useState, useRef, useContext } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from 'firebase/database';
import InputFR from '../../components/formComponents/inputText/InputFR';
import { TbAlertCircle } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { FirebaseAppContext } from '../../contexts/firebase';

export default function SignUp(props) {

  const signUpRef = useRef();

  const navigate = useNavigate();

  const [emailError, setEmailError] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");

  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorText, setPasswordErrorText] = useState("");

  const { auth, database } = useContext(FirebaseAppContext);

  const createAccount = () => {
    /*
      These statements remove any errors that were present
      from the previous sign up attempt. 
      Errors on current attempt handled after this.
     */
    if (passwordError) {
      setPasswordError(false);
    }
    if (emailError) {
      setEmailError(false);
    }

    const email = signUpRef.current[0].value;
    const password = signUpRef.current[1].value;
    const confirmPassword = signUpRef.current[2].value;

    console.log(email, password, confirmPassword);

    if (password === confirmPassword && password.length >= 6) {

      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          console.log("creating account for", userCredential.user.email);

          // Creating account in Firebase RTDB
          const user = userCredential.user;
          setEmailError(false);
          setPasswordError(false);
          setEmailErrorText("");

          set(ref(database, `users/${user.uid}`), {
            houses: {
              empty: true
            }
          })
          props.setShowAuthPop(false);
          props.setSignedIn(true);
          navigate("/home");
        })
        .catch((error) => {
          console.log(error.code);
          if (error.code === "auth/email-already-in-use") {
            setEmailError(true);
            setEmailErrorText("Email is already in use");
          }
          else if (error.code === 'auth/invalid-email' || error.code === 'auth/missing-email') {
            setEmailError(true);
            setEmailErrorText("Please enter a valid email");
          }
          else if (error.code === 'auth/internal-error') {
            setEmailError(true);
            setPasswordError(true);
            setEmailErrorText("Please enter valid credentials");
          }
          else {
            setEmailError(true);
            setEmailErrorText("There was an error. Try again later")
          }
        });
    } else {
      setPasswordError(true);
      if (signUpRef.current[1].value.length < 6) {
        setPasswordErrorText("Password must be at least six characters");
      } else if (signUpRef.current[1].value !== signUpRef.current[2].value) {
        setPasswordErrorText("Passwords do not match");
      }
    }
  }

  return (
    <div id="signUpContain">
      <form id="authForm"
        ref={signUpRef}
        onSubmit={(e) => {
          e.preventDefault();
          createAccount();
        }}>

        <div className='authTitles'>Sign Up
          <div className='signUpTagline'>Sign up to list your house, build your community, and simplify the search for recovery.</div>
        </div>

        {/* displays email error on Sign Up  */}
        <div className={emailError ? 'warning emailError error' : 'hideEmailError'}>
          <TbAlertCircle className='errorIconsAuth' />
          <div>
            {emailError && emailErrorText}
          </div>
        </div>

        <InputFR
          type="text"
          error={emailError}
          label="Email"
          blur={() => { return }}
        />

        {/* displays password error on Sign Up */}
        <div className={passwordError ? 'warning error' : 'warning'}>
          {passwordError && <TbAlertCircle className='errorIconsAuth' />}
          <div>
            {(passwordError && passwordErrorText) || "Password must be at least six characters"}
          </div>
        </div>

        <InputFR
          margin={"5px 0px 0px 0px"}
          type="password"
          error={passwordError}
          label="Password"
          blur={() => { return }}
        />

        <InputFR
          margin="20px 0px 0px 0px"
          type='password'
          error={passwordError}
          label="Confirm Password"
          width="100%"
          blur={() => { return }}
        />

        <input id="confirmButton"
          type="submit"
          value="Create Account"
        />

      </form>
    </div>
  )
}