import "./navMenu.css"
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { TbArrowBarRight, TbMail, TbLockSquare, TbLogout, /*TbUserCancel*/ TbUserHexagon } from "react-icons/tb";

import InfoPoints from "../locationScreenComponents/infoPoints/InfoPoints";
import { useContext } from "react";
import { FirebaseAppContext } from "../../contexts/firebase";


export default function NavMenu(props) {

  const auth = getAuth();
  const { user } = useContext(FirebaseAppContext);

  const navigate = useNavigate();

  const navMenu =
    [
      {
        identify: 'points',
        content: [
          {
            icon: <TbUserHexagon className="navIcon" />,
            label: "Admin",
            onClick: () => navigate('/admin')
          }
        ]
      },
      {
        identify: "title",
        label: "Profile Settings"
      },
      {
        identify: "points",
        content: [
          {
            icon: <TbMail className="navIcon" />,
            label: "Change Email",
            onClick: () => { props.setShowMenu(false); props.setShowChangeEmail(true); }
          },
          {
            icon: <TbLockSquare className="navIcon" />,
            label: "Change Password",
            onClick: () => { props.setShowMenu(false); props.setShowChangePassword(true); }
          },
          // todo - add delete account UI, should be similar to the app but will need its own pop
          // {
          //   icon: <TbUserCancel className="navIcon" />,
          //   label: "Delete Account",
          //   onClick: () => { return }
          // },
          {
            icon: <TbLogout className="navIcon" />,
            label: "Sign Out",
            onClick: () => { signOutHandler() }
          },
        ]
      },
    ]

  const signOutHandler = () => {
    signOut(auth).then(() => {
      props.setShowMenu(false);
      navigate("/", { replace: true });
    }).catch((error) => {
      console.log("error in signing out: " + error.message);
    });
  }

  return (
    <>
      <div className={props.showMenu ? "backgroundOverlayNavMenu backAnimateIn" : "backgroundOverlayNavMenu backAnimateOut"}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          pointerEvents: props.showMenu ? "auto" : "none",
          display: props.showChangePassword || props.showChangeEmail ? "none" : null
        }}
        onClick={() => { props.setShowMenu(false) }}
      />

      <div id="backgroundOverlayNavMenu" className="backgroundOverlayNavMenu">

        <div className={props.showMenu ? "containerSectionNavMenu menuAnimationIn" : "containerSectionNavMenu menuAnimationOut"}>
          <div id="navMenuContent">
            <TbArrowBarRight id="closeNavIcon" onClick={() => props.setShowMenu(false)} />

            <div style={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                {/* <TbUser strokeWidth={2} style={{ fontSize: "22px", color: "#707070", marginRight: "8px" }} /> */}
                <div style={{ fontSize: "16px", color: "#707070" }}>{user?.email}</div>
            </div>
            <div style={{marginTop: "15px"}}>
              <InfoPoints infoPoints={navMenu} navMenu={true} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}