/**
 * This function sets up the search filters to save space in the main 
 * Search function.
 * 
 * Here's how the map is set up:
 *  - Headings have a key of its display text with a value of "HEADING"
 *  - Checkboxes have a key of its Firebase tag with an object as its value 
 *    containing its display title, value, and gender tag if applicable.
 * 
 *  Docs for Maps in JavaScript:
 *  https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Map
 */

export default function getDefaultFilters() {
    
    const filters = new Map([

        // Gender Accommodations
        ["Gender Accomodations", "HEADING"],
        ["male", { tag: "gender", title: "Male", value: false, first: true }],
        ["female", { tag: "gender", title: "Female", value: false }],
        ["lgbtq", { tag: "gender", title: "LGBTQ+ Affirming", value: false }],

        // Recovery Support Options
        ["Recovery Support Options", "HEADING"],
        ["step12", { title: "On-Site 12-Step Program", value: false, first: true }],
        ["houseManagers", { title: "On-Site House Managers", value: false }],
        ["mentalHealthServices", { title: "Professional Mental Health Services", value: false }],
        ["faithBased", { title: "Faith-Based Recovery", value: false }],
        ["employmentAssistance", { title: "Employment Assistance", value: false }],
        ["peerGroupMeetings", { title: "Peer Group Meetings", value: false }],
        ["certified", { title: "Certified / Accredited", value: false }],

        //Policies
        ["Policies", "HEADING"],
        ["tobaccoUsage", { title: "Allows Tobacco Usage", value: false, first: true }],
        ["serviceAnimals", { title: "Allows Service Animals", value: false }],
        ["overnightVisitors", { title: "Allows Overnight Visitors", value: false }],
        ["kidFriendly", { title: "Kid Friendly", value: false }],

        // Accommodations and Finances
        ["Accommodations and Finances", "HEADING"],
        ["wheelchairAccessible", { title: "Wheelchair Accessible", value: false, first: true }],
        ["publicTransport", { title: "Access to Public Transportations", value: false }],
        ["pregnancySupport", { title: "Provides Pregnancy Support", value: false }],
        ["flexiblePayment", { title: "Allows Flexible Payment Options", value: false }],
        ["medicaid", { title: "Accepts Medicaid", value: false }],
        ["insurance", { title: "Accepts Insurance", value: false }],
    ]);

    return filters;
}