import './inputFR.css'
import { useRef, useState } from "react";

const InputFR = (props) => {

    const [focusedInput, setFocusedInput] = useState(false);
    const ref = useRef();

    return (
        <div className={false ? 'inputContainerForm' : 'inputContainer'} style={{ margin: props.margin || null, }}>
            <input
                onWheel={(e) => e.target.blur()}
                defaultValue={props.startValue || ""}
                type={props.type || "text"}
                autoComplete={props.autocomplete || "text"}
                ref={props.ref || ref}
                className={props.isForm ? "inputForm inputFormForm" : "inputForm"}
                style={{ borderColor: props.error && !ref.current?.value ? focusedInput ? "#2a8094" : "red" : null, width: props.width || "100%" }}
                placeholder="Title"
                onFocus={() => { setFocusedInput(true) }}
                onBlur={() => { props.blur(ref.current.value); setFocusedInput(false); }}
                id={props.id}
            />

            <div className={props.isForm ? 'labelAndBackgroundContainer labelAndBackgroundContainerForm' : 'labelAndBackgroundContainer'}>

                <div className='inputLabel'>
                    {props.label}
                </div>

            </div>

            {/* controls background color of text */}
            <div
                className={(ref.current?.value === ""
                    || ref.current?.value === undefined) && !focusedInput
                    ? 'labelAndBackgroundContainer backgroundColorAni'
                    : 'labelAndBackgroundContainer backgroundColorAni backgroundColorAniGo'}>
                {props.label}
            </div>

        </div>
    )
}
export default InputFR;