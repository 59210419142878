import '../signUp/signUp.css'
import React, { useState, useRef, useContext } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import InputFR from '../../components/formComponents/inputText/InputFR';
import { TbAlertCircle } from "react-icons/tb";
import { useNavigate } from 'react-router-dom';
import { FirebaseAppContext } from '../../contexts/firebase';

export default function SignIn(props) {
  const [error, setError] = useState(false);

  const { app } = useContext(FirebaseAppContext);
  const auth = getAuth(app);

  const navigate = useNavigate();

  const signInRef = useRef();

  const signIn = (email, password) => {
    console.log("here?")
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        console.log("signed in", auth.currentUser.email);
        setError(false);
        props.setShowAuthPop(false);
        props.setSignedIn(true);
        navigate("/home")
      })
      .catch((error) => {
        setError(true);
        console.log(error.message)
      });
  }

  return <div className='sign-up-div'>

    <form id="authForm"
      onSubmit={(e) => {
        e.preventDefault(); 
        console.log("submit")
        const email = signInRef.current[0].value;
        const password = signInRef.current[1].value;
        signIn(email, password);
      }}
      ref={signInRef}
    >

      <div className='authTitles'>Sign In</div>

      {/* displays error */}
      <div className={error ? 'warning emailError error' : 'hideEmailError'}>
        <TbAlertCircle className='errorIconsAuth' />
        <div>
          Invalid email or password
        </div>
      </div>

      <InputFR
        type="text"
        error={error}
        label="Email"
        id="email"
        blur={() => { return }}
      />

      <InputFR
        margin={"30px 0px 0px 0px"}
        type="password"
        autoComplete="current-password"
        error={error}
        label="Password"
        id="password"
        blur={() => { return }}
      />

      <div id="forgot-login" className="forgot"
        onClick={() => {
          props.setShowForgotPassword(true);
        }}>

        <div className='forgotText'>
          Forgot your password?
        </div>

      </div>

      <input id="confirmButton"
        type="submit"
        value={"Sign In"}
      />

    </form>

  </div>
}