import './search.css';
import '../locationScreen/locationScreen.css'
import SearchResults from '../../components/searchComponents/searchResults/SearchResults';
import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import LocationSearchBar from '../../components/searchComponents/locationSearchBar/LocationSearchBar';
import SearchFilters from '../../components/searchComponents/searchFilters/SearchFilters';

import { ref, onValue } from 'firebase/database';
import getDefaultFilters from '../../functions/searchFunctions/getDefaultFilters';
import runSearch from '../../functions/searchFunctions/runSearch';

import LocationScreen from '../locationScreen/LocationScreen';

import { useLocation } from 'react-router-dom';
import { FirebaseAppContext } from '../../contexts/firebase';

function Search() {
  const { database, databaseKey } = useContext(FirebaseAppContext);

  const location = useLocation();

  const [bookmarkedHouses, setBookmarkedHouses] = useState(location?.state?.bookmarkedHouses || [])

  const [selectedHouse, setSelectedHouse] = useState(false);
  const [houseInfo, setHouseInfo] = useState(false);
  const [distanceDisplay, setDistanceDisplay] = useState("");

  const currentLocation = location?.state?.currentLocation;
  const currentLocationText = location?.state?.currentLocationText;

  const [usedLocation, setUsedLocation] = useState(location?.state?.useLocation);

  const [providersList, setProvidersList] = useState(new Set());
  const [filteredData, setFilteredData] = useState([]);

  const [filtersVisible, setFiltersVisible] = useState(false);

  //document.body.style.overflow = filtersVisible || selectedHouse ? "hidden" : "visible";

  // Loading information from database
  useEffect(() => {
    if (providersList.size === 0) {
      const tempSet = new Set();
      onValue(ref(database, databaseKey), (snapshot) => {
        snapshot.forEach((item) => {
          const childData = { ...item.val(), key: item.key };
          if (!tempSet.has(childData)) {
            if (!childData.pending) {
              tempSet.add(childData);
            }
          }
        })
        setProvidersList(new Set(tempSet));
        setFilteredData(Array.from(tempSet));
      }, { onlyOnce: true },
        (error) => {
          console.log(error.message);
        });
    }

    // rest of the useEffect was a navigation.setOptions that updated bookmark list internally

  }, [providersList.size, database, databaseKey]);

  const [filters, setFilters] = useState(new Map(getDefaultFilters()));
  const [distance, setDistance] = useState(100000);
  const [state, setState] = useState(null);
  const [narr, setNarr] = useState(null);
  const [mat, setMAT] = useState(null);
  const [weeklyFee, setWeeklyFee] = useState({ min: 0, max: Number.MAX_VALUE });

  const [sortDistance, setSortDistance] = useState("close");
  const [sortBookmarks, setSortBookmarks] = useState("none");

  const resetSearch = () => {
    setFilters(new Map(getDefaultFilters()));
    setDistance(100000);
    setState(null);
    setMAT(null);
    setWeeklyFee({ min: 0, max: Number.MAX_VALUE });
    setSortDistance("close");
    setSortBookmarks("none");
    setNarr(null);
  }

  useLayoutEffect(() => {
    const results = runSearch(providersList, distance, weeklyFee, filters, usedLocation, mat, bookmarkedHouses, sortDistance, sortBookmarks, parseInt(narr), state)
    setFilteredData(results.filter((item) => item?.isSuspended !== true))
    window.scrollTo(0, 0);
  }, [distance, state, filters, usedLocation, mat, providersList, weeklyFee, bookmarkedHouses, sortDistance, sortBookmarks, narr]);

  return (
    <div className="searchContain" id="searchContainId"> 
    {/* //style={{ overflow: filtersVisible ? "hidden" : "visible" }}> */}
      <div>

        <div id="locationSearchBarContain">
          <LocationSearchBar
            bookmarkedHouses={bookmarkedHouses}
            currentLocation={currentLocation}
            currentLocationText={currentLocationText}
            startText={location?.state?.locationText}
            onNewSearch={(e) => { setUsedLocation(e.location); }}
            onUseCurrent={(e) => { setUsedLocation(e.location); }}
            onShowFilters={setFiltersVisible}
          />
        </div>

        <div className='search'>

          <SearchFilters
            usedLocation={usedLocation}

            filteredData={filteredData}

            distance={distance}
            onChangeDistance={(e) => setDistance(e)}

            state={state}
            onChangeState={(e) => setState(e)}

            narr={narr}
            onChangeNARR={(e) => setNarr(e)}
            mat={mat}
            onChangeMAT={(e) => setMAT(e)}

            weeklyFee={weeklyFee}
            onChangeFee={(e) => setWeeklyFee(e)}

            sortDistance={sortDistance}
            onChangeSortDistance={setSortDistance}
            sortBookmarks={sortBookmarks}
            onChangeSortBookmarks={setSortBookmarks}

            filters={filters}
            onChangeFilters={(e) => setFilters(e)}

            onReset={resetSearch}
            onSearch={setFiltersVisible}

            filtersVisible={filtersVisible}
          />

          <div className='searchResultsContainer'>
            <SearchResults
              setDistanceDisplay={setDistanceDisplay}
              bookmarkedHouses={bookmarkedHouses}
              setBookmarkedHouses={setBookmarkedHouses}
              setSelectedHouse={setSelectedHouse}
              setHouseInfo={setHouseInfo}
              results={filteredData}
              usedLocation={usedLocation}
            />
          </div>

        </div>

        <div className={selectedHouse ? "backgroundOverlay houseFadeIn" : "backgroundOverlay houseFadeOut"}
          style={{
              zIndex: 3,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              pointerEvents: selectedHouse ? "auto" : "none"
            }}
          onClick={() => { setSelectedHouse(false) }}
        />

        {selectedHouse && <div id="locationScreenContainSearch">
            <LocationScreen
              distanceDisplay={distanceDisplay}
              bookmarkedHouses={bookmarkedHouses}
              setBookmarkedHouses={setBookmarkedHouses}
              setSelectedHouse={setSelectedHouse}
              houseInfo={houseInfo}
            />
          </div>
        }

      </div>
    </div>
  );
}

export default Search;
