import './dropdownFR.css'
import React from 'react';
import { useState } from "react";
import { TbChevronDown } from "react-icons/tb";

const DropdownFRState = (props) => {
    //in this case, this is just used to force a rerender once 
    // after the first onBlur to trigger the animation
    const [, setFocusedInput] = useState(false);

    return (
        <div style={{ margin: props.margin }}/* select elements just don't respect margins properly */>
            <div className='containerDrop' style={{ width: props.width || "100%" }}>
                <select
                    value={props.value === 100000 ? "" : props.value !== null ? props.value : ""}
                    onChange={(e) => { props.onChange(e.target.value) }}
                    className="dropForm"
                    style={{
                        color: props.value !== null && props.value !== 100000 ? "black"
                            : "transparent", width: "100%"
                    }}
                    onBlur={() => { setFocusedInput(true) }}
                    id={props.id}
                >
                    {
                        props.optionValues.map((element, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {index === 0 ?
                                        <option style={{ color: "transparent" }} value="" disabled hidden>
                                            {element.placeholder}
                                        </option>
                                        :
                                        <option style={{ color: "black" }} disabled={props.disabled} value={element.value}>
                                            {element.label}
                                        </option>
                                    }
                                </React.Fragment>
                            )
                        })
                    }
                </select>


                {/* div controls animation */}
                <div className={props.value !== null && props.value !== 100000
                    ? 'dropLabelAndBackgroundContainerFirst dropLabelAndBackgroundContainerAfterFirst'
                    : 'dropLabelAndBackgroundContainerFirst'}
                >
                    {
                        props.value !== null && props.value !== 100000 ?
                            <div className='dropLabel'>
                                {props.optionValues[0].placeholder}
                            </div>
                            :
                            <div className='dropLabelFirst'>
                                {props.optionValues[0].placeholder}
                            </div>
                    }

                </div>

                <div className={props.value !== null && props.value !== 100000 ? 'blockLine blockLineAni' : 'blockLine'}>
                    {props.optionValues[0].placeholder}
                </div>

                <div className='iconDropContain'>
                    <TbChevronDown className='iconDrop' />
                </div>
            </div>

        </div>
    )
}
export default DropdownFRState;