import './home.css';
import "../../components/homeHeaderComponent/header.css";
import { useState, useEffect } from "react";
import { useNavigate, useLocation, useLoaderData } from "react-router-dom";
import AuthContainer from "../../components/pops/authContainer/AuthContainer";
import Header from "../../components/homeHeaderComponent/Header";
import Button from '../../components/Button/Button';
import "../../components/searchComponents/locationSearchBar/LocationSearchBar.css"
import "../../components/pops/authContainer/authContainer.css";
import LocationSearch from '../../components/locationSearch/LocationSearch';
import Disclaimer from '../../components/pops/disclaimers/Disclaimer';
import Survey from '../../components/pops/survey/Survey';
import PopBackground from '../../components/pops/PopBackground';

const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showAuthPop, setShowAuthPop] = useState(false);
    const [onSignIn, setOnSignIn] = useState(true);
    const [signedIn, setSignedIn] = useState(false);

    const bookmarkedHouses = location?.state?.bookmarkedHouses || [];

    const data = useLoaderData();
    const currentLocation = data?.location;
    const currentLocationText = data?.text;

    const [showSearchDisclaimerPop, setShowSearchDisclaimerPop] = useState(localStorage.getItem("searchDisclaimer") !== "true");

    const [surveyVisible, setSurveyVisible] = useState(location.state?.survey);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showAuthPop, showSearchDisclaimerPop]);

    return <>
        <div id="scrollNav" style={{ backgroundColor: "white", marginTop: "63px" }}>

            <Header
                bookmarkedHouses={bookmarkedHouses}
                setSignedIn={setSignedIn}
                signedIn={signedIn}
                setShowAuthPop={setShowAuthPop}
                setOnSignIn={setOnSignIn}
            />

            <div className="homeBasisContain">

                <div className='searchSide'>

                    <h2 className="titleHome">
                        Search R-silience to find your home for recovery.
                    </h2>

                    <LocationSearch
                        homeScreen={true}  // change to true if styling changes
                        currentLocationText={currentLocationText}
                        currentLocation={currentLocation}
                        onNewSearch={(e) => navigate("/search",
                            {
                                state: {
                                    bookmarkedHouses: location?.state?.bookmarkedHouses || [],
                                    currentLocationText: currentLocationText,
                                    currentLocation: currentLocation,
                                    useLocation: e.location,
                                    locationText: e.text
                                }
                            }
                        )}
                        onUseCurrent={(values) => navigate("/search",
                            {
                                state: {
                                    bookmarkedHouses: location?.state?.bookmarkedHouses || [],
                                    currentLocationText: values.text,
                                    currentLocation: values.location,
                                    useLocation: values.location,
                                    locationText: values.text
                                }
                            }
                        )}
                    />

                    <div className="titleHomeSubSub">Search anonymously. No sign in required.</div>

                </div>

                <div className="bottomSectionContainer">

                    <h3 className='bottomTextHeader' style={{ fontWeight: 200 }}>
                        Do you manage a recovery house?
                    </h3>

                    <Button
                        text="Add My Recovery House"
                        size="large"
                        onClick={() => setShowAuthPop(true)}
                        width={350}
                    />

                </div>

            </div>

            <PopBackground
                visible={showAuthPop}
                hidePop={setShowAuthPop}
                navOut={true}
                component={
                    <AuthContainer
                        setSignedIn={setSignedIn}
                        setShowAuthPop={setShowAuthPop}
                        onSignIn={onSignIn}
                        disableBodyScroll={true}
                    />
                }
                centered={true}
            />

            <PopBackground
                visible={showSearchDisclaimerPop}
                navOut={false}
                component={<Disclaimer
                    type="search"
                    onAgree={() => {
                        localStorage.setItem("searchDisclaimer", "true");
                        setShowSearchDisclaimerPop(false);
                    }}
                />}
                centered={true}
            />

        </div>

        {
            surveyVisible && <Survey
                controlledBy={surveyVisible}
                navAfter={() => setSurveyVisible(false)}
            />
        }
    </>
}
export default Home;