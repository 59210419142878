import React, { createContext, useLayoutEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getDatabase, get, child, ref } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(config);
const auth = getAuth(app);
const database = getDatabase(app);
const storage = getStorage(app);
const databaseKey =  process.env.REACT_APP_DATABASE_KEY

export const FirebaseAppContext = createContext(undefined);

export const FirebaseAppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(false);
  const [helper, setHelper] = useState(false);
  const [surveyCompleted, setSurveyCompleted] = useState(false);
  const [surveyDateDismissed, setSurveyDateDismissed] = useState(null);

  useLayoutEffect(() => {
    onAuthStateChanged(auth, (data) => {
      setUser(data);

      get(child(ref(database), `users/${data?.uid}`))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const val = snapshot.val();

            setAdmin(val.admin);
            setHelper(val.helper);

            setSurveyCompleted(val.surveyCompleted);
            setSurveyDateDismissed(new Date(val.surveyDateDismissed) || null);
          }
        }).catch(() => {
          setAdmin(false);
          setHelper(false);

          // fall back on local storage for survey completion if not signed in
          setSurveyCompleted(localStorage.getItem("surveyCompleted") === "true");
          setSurveyDateDismissed(new Date(localStorage.getItem("surveyDateDismissed")));
        })

    });
  }, []);

  return (
    <FirebaseAppContext.Provider value={{
      app, auth, database, user, admin, storage, databaseKey, helper,
      surveyCompleted, surveyDateDismissed, setSurveyCompleted, setSurveyDateDismissed
    }}>
      {children}
    </FirebaseAppContext.Provider>
  );
};